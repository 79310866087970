import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./components/Header";
import Login from "./components/Login";
import FactoryLabel from './components/factory/FactoryLabel';
import NewFactoryLabel from './components/newFactory/FactoryLabel';
import OutboundLabel from './components/outbound/OutboundLabel';
import SkuLabel from './components/sku/SkuLabel';
import QRCodeScanner from "./components/qrcode/QRCodeScanner";
import { inject, observer } from "mobx-react";
import SVGSymbols from './components/SVGIcons';
import Cookies from "js-cookie";
import OutboundQRCodeScanner from "./components/outboundQRCode/OutboundQRCodeScanner";
import StockCheck from "./components/stockCheck/StockCheck";
import StockCheckUpdate from "./components/stockCheck/StockCheckUpdate";
import ComponentStock from "./components/componentStock/ComponentStock";

@inject("UserStore", "PrintStore")
@observer
class App extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    const { UserStore, PrintStore } = this.props;

    PrintStore.getPrinterStatus();

    if (Cookies.get("token")) {
      UserStore.authedUser = Cookies.get("token");
    }
    this.setState({ loading: false });
  }

  render() {
    const { UserStore } = this.props;
    if (this.state.loading) {
      return <div />
    }

    return (
      <div className="App">
        <Router>
          <Fragment>
            <SVGSymbols />
            {UserStore.authedUser && <Header />}
            {!UserStore.authedUser
              ? <Route exact path="/" component={Login} />
              : <Fragment>
                  <Route exact path={["/", "/factory"]} component={FactoryLabel} />
                  <Route exact path="/new-factory" component={NewFactoryLabel} />
                  <Route exact path="/outbound" component={OutboundLabel} />
                  <Route exact path="/sku" component={SkuLabel} />
                  <Route exact path="/qrcode" component={QRCodeScanner} />
                  <Route exact path="/outbound-qrcode" component={OutboundQRCodeScanner} />
                  <Route exact path="/stock-check" component={StockCheck} />
                  <Route exact path="/stock-check/meals" component={StockCheckUpdate} />
                  <Route exact path="/stock-check/addons" component={StockCheckUpdate} />
                  <Route exact path="/component-stock" component={ComponentStock} />
                </Fragment>
            }
          </Fragment>
        </Router>
      </div>
    );
  }
}

export default App;