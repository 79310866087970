import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { DateInput, DateRangeInput } from "@blueprintjs/datetime";
import moment from "moment";
import FactorySkuList from './FactorySkuList';

@inject('NewFactoryStore', 'PrintStore')
@observer
class FactoryLabelAutomatic extends Component {
    handleSearch = () => {
        const { NewFactoryStore: { handleGetSkus } } = this.props;
        handleGetSkus();
    }

    render() {
        const {
            NewFactoryStore: {
                productionBatches,
                handleSetProductionDate,
                productionDate,
                selectedSkusToPrint,
                setSelectedSkusToPrint,
            },
            openEditBatchWindow,
            setPrintModalState
        } = this.props;

        return (
            <Fragment>
                <div className="btn-wrapper ml">
                    <DateInput
                        formatDate={date => moment(date).date() === moment().date()
                            ? moment(date).format('[Today •] DD/MM/YYYY')
                            : moment(date).format('DD/MM/YYYY')
                        }
                        onChange={date => handleSetProductionDate(moment(date))}
                        parseDate={str => moment(str, 'DD/MM/YYYY').toDate()}
                        placeholder="Pick a date"
                        value={moment(productionDate, 'DD/MM/YYYY').toDate()}
                        inputProps={{ leftIcon: "calendar" }}
                        className="date-input single"
                        maxDate={new Date(2030,12,31)}
                    />
                    <span className="separator" />
                    <button className="btn mlxs" onClick={this.handleSearch}>
                        Search
                    </button>
                </div>
                {productionBatches && (
                    <Fragment>
                        <div className="multi-select-row">
                        {Object.keys(productionBatches)
                            .sort()
                            .map(sku =>
                                <div
                                    key={sku}
                                    className={`multi-select-row__option ${selectedSkusToPrint.includes(sku) ? 'selected' : ''}`}
                                    onClick={() => setSelectedSkusToPrint(sku)}
                                >
                                    {sku.split('_')[0]}
                                </div>

                            )
                        }
                        </div>
                        <FactorySkuList
                            openEditBatchWindow={openEditBatchWindow}
                            setPrintModalState={setPrintModalState}
                        />
                    </Fragment>
                )}
            </Fragment>
        )
    }
}

export default FactoryLabelAutomatic;
