import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';

const base = 'transfer-list'

@inject('FactoryStore')
@observer
class FactorySkuList extends Component {
    render() {
			const { FactoryStore: {
				getPalletCountPerSku,
				selectedSkusToPrint,
			}} = this.props;

			return (
				<div className={`${base}`}>
                    {Object.keys(selectedSkusToPrint)
                        .sort()
						.map(mealLetter => (
							<Fragment key={mealLetter}>
								<div className={`${base}__header`}>{`Type ${mealLetter}`}</div>
								{selectedSkusToPrint[mealLetter]
									.sort()
									.map(sku => (
									<Fragment key={sku}>
										<div className={`${base}__meal-sku`}>{sku}</div>
										<div className={`${base}__meal-count`}>{getPalletCountPerSku(mealLetter, sku)}</div>
									</Fragment>
								))}
							</Fragment>
						))
					}
				</div>
			);
  	}
}

export default FactorySkuList;
