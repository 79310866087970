import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import FactoryLabelAutomatic from './FactoryLabelAutomatic';
import FactoryLabelManual from './FactoryLabelManual'
import Loading from '../Loading';

@inject('FactoryStore')
@observer
class FactoryLabel extends Component {
    state = {
        isPrintManual: false,
    }

    handleChangePrintMode = () => {
        this.setState(prevState => ({ 
            isPrintManual: !prevState.isPrintManual
        }));
    }

	render() {
        const { isPrintManual  } = this.state;
        const { FactoryStore: { loading } } = this.props;

        if (loading) {
            return <Loading />
        }
        
        return (
            <Fragment>
                <h1 className="mtxl">Factory Label</h1>
                <button className="btn" onClick={this.handleChangePrintMode}>
                    {isPrintManual ? 'Go back' : 'Go to manual print mode'}
                </button>
                {isPrintManual ? (
                    <FactoryLabelManual />
                ) : (
                    <FactoryLabelAutomatic />
                )}
            </Fragment>
        )
    }
}

export default FactoryLabel;
