import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';

@inject('SkuStore', 'PrintStore')
@observer
class SkuLabel extends Component {
    state = {
        sku: '',
        total: '',
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value.toUpperCase()});
    }

    handleClickPrint = e => {
        const { SkuStore } = this.props;
        const fields = {...this.state};
        e.preventDefault();
        SkuStore.createSkuPrintZpl(true, fields);
    };

	render() {
        const {
            sku,
            total,
        } = this.state;
        const { PrintStore: { isPrinterConnected } } = this.props;

        const disabled = !isPrinterConnected || !sku || !total;
        
        return (
            <Fragment>
                <h1 className="mtxl">SKU Label</h1>
                <form className="form-grid">
                    <label className="form-label">SKU</label>
                    <input
                        className="form-input border--rounded border--solid"
                        required
                        name="sku"
                        type="text"
                        value={sku}
                        onChange={this.handleChange}
                        placeholder="A03_31"
                    />
                    <label className="form-label">Total</label>
                    <input
                        className="form-input border--rounded border--solid"
                        required
                        name="total"
                        type="number"
                        value={total}
                        onChange={this.handleChange}
                        placeholder="45"
                    />
                    <button className="btn form-button" onClick={this.handleClickPrint} disabled={disabled}>
                        <svg fill="#fff" className="iconmoon-icon"><use xlinkHref={`#icon-print`} /></svg>
                        Print
                    </button>
                </form>
            </Fragment>
        )
    }
}

export default SkuLabel;
