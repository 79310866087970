import React from 'react';
import ReactDOM from 'react-dom';
import './resources/scss/main.scss';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import {Provider} from "mobx-react";
import UserStore from "./stores/UserStore";
import HeaderStore from "./stores/HeaderStore";
import PrintStore from "./stores/PrintStore";
import OutboundStore from "./stores/OutboundStore";
import FactoryStore from "./stores/FactoryStore";
import NewFactoryStore from "./stores/NewFactoryStore";
import SkuStore from "./stores/SkuStore";
import QRCodeStore from "./stores/QRCodeStore";
import StockCheckStore from "./stores/StockCheckStore";
import ComponentStockStore from "./stores/ComponentStockStore";

const stores = {
  UserStore,
  HeaderStore,
  PrintStore,
  OutboundStore,
  FactoryStore,
  NewFactoryStore,
  SkuStore,
  QRCodeStore,
  StockCheckStore,
  ComponentStockStore
};

ReactDOM.render(<Provider {...stores}><App /></Provider>, document.getElementById('root'));
registerServiceWorker();
