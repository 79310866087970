import React from 'react';

export const BrandText = ({ className = '' }) => {
    return (
        <div className="display--inline-block">
            <svg height="100%" className={ `logo__text ${className}` } version="1.1" viewBox="0 0 759.571 131.958" x="0px" y="0px" xmlSpace="preserve">
                <g>
                    <path d="M65.938,2.117v63.234c-4.92-8.408-15.235-12.695-26.188-12.695c-20.318,0-37.306,14.922-37.306,38.575&#xA;&#x9;&#x9;c0,23.486,17.145,38.727,37.463,38.727c10.319,0,21.111-4.6,26.031-13.014v1.137c0,5.682,4.606,10.289,10.288,10.289h4.158V2.117&#xA;&#x9;&#x9;H65.938z M41.176,115.831c-13.333,0-23.967-10.158-23.967-24.762c0-14.76,10.634-24.285,23.967-24.285&#xA;&#x9;&#x9;c11.905,0,24.605,8.412,24.605,24.285C65.782,106.786,54.19,115.831,41.176,115.831" />
                    <path d="M163.254,116.944c-4.92,8.414-15.712,13.014-26.032,13.014c-20.317,0-37.618-15.24-37.618-38.727&#xA;&#x9;&#x9;c0-23.652,17.301-38.575,37.618-38.575c10.32,0,21.112,4.287,26.032,12.695V54.245h14.284v74.125h-3.996&#xA;&#x9;&#x9;c-5.682,0-10.288-4.607-10.288-10.289V116.944z M138.493,66.784c-13.333,0-24.124,9.525-24.124,24.285&#xA;&#x9;&#x9;c0,14.604,10.791,24.762,24.124,24.762c13.175,0,24.604-9.045,24.604-24.762C163.097,75.196,150.398,66.784,138.493,66.784" />
                    <path d="M201.382,128.37h-4.152V2.113h14.441v63.239c4.443-8.889,16.349-12.695,24.446-12.695&#xA;&#x9;&#x9;c18.567,0,30.158,12.062,30.001,32.857v42.855h-14.445V86.465c0-12.221-7.305-19.682-17.464-19.682&#xA;&#x9;&#x9;c-10.157,0-22.538,5.711-22.538,21.584v29.713C211.671,123.764,207.064,128.37,201.382,128.37" />
                    <path d="M350.41,88.37v40.002h-14.446V86.305c0-12.225-6.352-19.523-16.031-19.523&#xA;&#x9;&#x9;c-10.314,0-20.636,6.033-20.636,21.588v40.002H284.85V54.244h14.446v11.11c3.968-8.255,14.446-11.906,20.479-12.38&#xA;&#x9;&#x9;c2.855-0.162,5.554,0,8.095,0.475c9.363,1.428,15.874,6.348,19.36,14.447c6.828-13.17,18.42-15.235,25.72-15.235&#xA;&#x9;&#x9;c18.57,0,28.572,12.062,28.572,32.854v42.857h-4.157c-5.683,0-10.289-4.605-10.289-10.289V86.467&#xA;&#x9;&#x9;c0-12.225-6.347-19.686-16.986-19.686C357.866,66.625,350.41,75.993,350.41,88.37" />
                    <path d="M484.558,116.944c-4.92,8.414-15.712,13.014-26.032,13.014c-20.317,0-37.618-15.24-37.618-38.727&#xA;&#x9;&#x9;c0-23.652,17.301-38.575,37.618-38.575c10.32,0,21.112,4.287,26.032,12.695V54.245h14.289v74.125h-5.47&#xA;&#x9;&#x9;c-4.871,0-8.819-3.949-8.819-8.82V116.944z M459.796,66.784c-13.333,0-24.124,9.525-24.124,24.285&#xA;&#x9;&#x9;c0,14.604,10.791,24.762,24.124,24.762c13.175,0,24.605-9.045,24.605-24.762C484.402,75.196,471.702,66.784,459.796,66.784" />
                    <path d="M522.528,128.37h-3.996V2.113h14.284v77.999l25.557-25.871h19.685L546.31,86.303l39.365,42.066h-19.047&#xA;&#x9;&#x9;L532.816,92.18v25.9C532.816,123.764,528.209,128.37,522.528,128.37" />
                    <path d="M656.957,116.944c-4.92,8.414-15.712,13.014-26.033,13.014c-20.317,0-37.618-15.24-37.618-38.727&#xA;&#x9;&#x9;c0-23.652,17.301-38.575,37.618-38.575c10.321,0,21.113,4.287,26.033,12.695V54.245h14.289v74.125h-2.869&#xA;&#x9;&#x9;c-6.307,0-11.42-5.113-11.42-11.42V116.944z M632.195,66.784c-13.333,0-24.124,9.525-24.124,24.285&#xA;&#x9;&#x9;c0,14.604,10.791,24.762,24.124,24.762c13.175,0,24.604-9.045,24.604-24.762C656.799,75.196,644.099,66.784,632.195,66.784" />
                    <path d="M705.375,128.37h-14.441V54.241h14.441v11.111c4.444-8.889,15.079-12.695,23.177-12.695&#xA;&#x9;&#x9;c18.415,0,28.574,12.062,28.574,32.857v42.855h-2.688c-6.493,0-11.758-5.264-11.758-11.758V86.465&#xA;&#x9;&#x9;c0-12.383-6.19-19.682-16.35-19.682c-10.478,0-20.955,5.873-20.955,21.584V128.37z" />
                </g>
            </svg>
        </div>
    );
};

export const PrimeLogo = ({ className = '', withDahmakan = true, fillColor = 'none', leafColor = '#00AECB'}) => {
    return (
        <div className="display--inline-block">
            <svg width="106px" className={ `logo__text ${className}` } height="48px" viewBox="0 0 106 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="PW0---Prime-packages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(-676.000000, -11.000000)">
                    <g id="Group-13" transform="translate(676.000000, 11.000000)">
                        <g id="Group-4">
                            <path d="M5.9337,6.8062 L5.9337,15.5572 L11.8487,15.5572 C14.5237,15.5572 15.7787,13.2882 15.7787,11.2222 C15.7787,9.1962 14.6037,6.8062 12.0117,6.8062 L5.9337,6.8062 Z M0.7477,2.1882 L12.0917,2.1882 C18.2907,2.1882 20.9637,6.6852 20.9637,11.2222 C20.9637,15.7592 18.2907,20.1752 12.0917,20.1752 L5.9337,20.1752 L5.9337,30.1012 L0.7477,30.1012 L0.7477,2.1882 Z" id="Fill-3" fill={`${fillColor}`}></path>
                            <path d="M30.5604,30.101 L25.6194,30.101 L25.6194,11.182 L30.5604,11.182 L30.5604,15.638 C31.5324,12.194 33.7614,10.776 35.9084,10.776 C37.2454,10.776 38.2584,11.06 39.0274,11.465 L38.1764,15.922 C37.0024,15.395 35.7874,15.395 35.3014,15.395 C31.9384,15.395 30.5604,18.393 30.5604,23.417 L30.5604,30.101 Z" id="Fill-4" fill={`${fillColor}`}></path>
                            <polygon id="Fill-5" fill={`${fillColor}`} points="42.265 30.1015 47.167 30.1015 47.167 11.1825 42.265 11.1825"></polygon>
                            <path d="M70.0919,20.0135 L70.0919,30.1005 L65.1509,30.1005 L65.1509,19.6895 C65.1099,17.0555 63.6109,15.5975 61.6659,15.5975 C59.6399,15.5975 57.6949,16.7725 57.6949,20.0135 L57.6949,30.1005 L52.7539,30.1005 L52.7539,11.1815 L57.6949,11.1815 L57.6949,13.9765 C58.7499,11.6675 61.3429,10.8175 63.1249,10.8175 C66.1229,10.9785 68.1079,12.0325 69.1599,14.3415 C70.9429,11.2225 73.6179,10.7765 75.1169,10.7765 C79.6939,10.7765 82.4079,13.7345 82.4079,19.2845 L82.4079,30.1005 L77.5059,30.1005 L77.5059,19.8105 C77.5059,17.0965 75.9659,15.5975 73.9409,15.5975 C71.7139,15.5975 70.0919,17.1375 70.0919,20.0135" id="Fill-6" fill={`${fillColor}`}></path>
                            <path d="M101.1577,18.9197 C100.7937,16.3267 98.8487,14.9087 96.4177,14.9087 C93.8257,14.9087 91.7997,16.3667 91.4347,18.9197 L101.1577,18.9197 Z M105.8987,22.2827 L91.4757,22.2827 C91.7997,24.7937 93.9057,26.3337 96.7017,26.3337 C98.6057,26.3337 100.5507,25.5227 101.4417,23.9027 C102.6167,24.5507 103.9937,25.2807 105.2097,25.9277 C103.5077,29.1697 99.8617,30.5057 96.3777,30.5057 C90.9897,30.5057 86.5737,26.5357 86.5737,20.5807 C86.5737,14.6257 90.9897,10.7767 96.3777,10.7767 C101.7657,10.7767 105.9787,14.6257 105.9787,20.5807 C105.9787,21.0667 105.9387,21.8367 105.8987,22.2827 L105.8987,22.2827 Z" id="Fill-7" fill={`${fillColor}`}></path>
                            <path d="M49.6185,1.4729 C49.6155,1.0149 49.5595,0.5719 49.4595,0.1449 C49.0305,0.0479 48.5855,-0.0021 48.1295,-0.000100000001 C44.8725,0.0179 42.2485,2.6729 42.2665,5.9289 C42.2685,6.3849 42.3255,6.8299 42.4255,7.2559 C42.8535,7.3529 43.2995,7.4039 43.7555,7.4019 C47.0115,7.3839 49.6365,4.7289 49.6185,1.4729" id="Fill-8" fill={`${leafColor}`}></path>
                            { withDahmakan ?
                                <text id="By-dahmakan" fontFamily="SofiaProRegular, Sofia Pro" fontSize="11.3367" fontWeight="normal" letterSpacing="-0.1247037" fill="#00AECB">
                                    <tspan x="17.5381" y="44.226077">B</tspan>
                                    <tspan x="24.4081402" y="44.226077" letterSpacing="-0.2834175">y</tspan>
                                    <tspan x="29.6116855" y="44.226077"> dahma</tspan>
                                    <tspan x="69.9363274" y="44.226077" letterSpacing="-0.3287643">k</tspan>
                                    <tspan x="74.9698222" y="44.226077">an</tspan>
                                </text>
                                :
                                null
                            }                            
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export const BlackLogo = ({ className = '' }) => {
    return (
        <div className={ className }>
            <BrandText className="logo__text logo__text--default-black" />
            <Leaf size="8" color="green" />
        </div>
    );
};

export const WhiteLogoWhiteLeaf = ({ className = '', withLeaf= true }) => {
    return (
        <div className={ className }>
            <BrandText className="logo__text logo__text--default-white" />
            { withLeaf ? 
                <Leaf size="8" color="white" />
                :
                null
            }            
        </div>
    );
};

export const Leaf = ({ size = '20', color = 'green', className = '' }) => {
    return (
        <div className="display--inline-block">
            <svg className={ `logo logo--${color} ${className}` } viewBox="0 0 25.8 25.8" width={ size }>
                <path d="M25.8 5.2c0-1.6-.2-3.1-.5-4.6-1.5-.3-3-.5-4.6-.5C9.2 0 0 9.2 0 20.6c0 1.6.2 3.1.5 4.6 1.5.3 3 .5 4.6.5 11.4.1 20.7-9.2 20.7-20.5" />
            </svg>
        </div>
    );
};