import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { DateInput, DateRangeInput } from "@blueprintjs/datetime";
import moment from "moment";
import FactorySkuList from './FactorySkuList';

@inject('FactoryStore', 'PrintStore')
@observer
class FactoryLabelAutomatic extends Component {
    handleSearch = () => {
        const { FactoryStore: { handleGetSkus } } = this.props;
        handleGetSkus();
    }

    handleClickPrint = e => {
        const { FactoryStore: { createFactoryPrintZplAuto, skus } } = this.props;
        e.preventDefault();
        createFactoryPrintZplAuto(!!skus);
    };

    render() {
        const {
            FactoryStore: {
                dateRange,
                productionBatches,
                getPalletCountPerSku,
                handleSetDateRange,
                handleSetProductionDate,
                productionDate,
                selectedSkusToPrint,
                setSelectedSkusToPrint
            },
            PrintStore: {
                isPrinterConnected
            }
        } = this.props;

        return (
            <Fragment>
                <div className="btn-wrapper ml">
                    <DateRangeInput
                        formatDate={date => moment(date).format('DD/MM/YYYY')}
                        onChange={dateRange => handleSetDateRange(dateRange)}
                        parseDate={str => moment(str, 'DD/MM/YYYY').toDate()}
                        value={dateRange}
                        shortcuts={false}
                        className="date-input"
                        maxDate={new Date(2030,12,31)}
                    />
                    <button className="btn mlxs" onClick={this.handleSearch}>
                        Search
                    </button>
                </div>
                <div className="btn-wrapper ml">
                    <DateInput
                        formatDate={date => moment(date).date() === moment().date()
                            ? moment(date).format('[Today •] DD/MM/YYYY')
                            : moment(date).format('DD/MM/YYYY')
                        }
                        onChange={date => handleSetProductionDate(moment(date))}
                        parseDate={str => moment(str, 'DD/MM/YYYY').toDate()}
                        placeholder="Pick a date"
                        value={moment(productionDate, 'DD/MM/YYYY').toDate()}
                        inputProps={{ leftIcon: "calendar" }}
                        className="date-input single"
                        maxDate={new Date(2030,12,31)}
                    />
                    <span className="separator" />
                    <button className="btn" onClick={this.handleClickPrint} disabled={!isPrinterConnected || !productionBatches}>
                        <svg fill="#fff" className="iconmoon-icon"><use xlinkHref={`#icon-print`} /></svg>
                        Print
                    </button>
                </div>
                {productionBatches && (
                    <Fragment>
                        <div className="multi-select-row">
                        {Object.keys(productionBatches)
                            .sort()
                            .map(mealLetter =>
                                Object.keys(productionBatches[mealLetter])
                                    .sort()
                                    .map(sku => 
                                        <div
                                            key={sku}
                                            className={`multi-select-row__option ${selectedSkusToPrint[mealLetter].includes(sku) ? 'selected' : ''}`}
                                            onClick={() => setSelectedSkusToPrint(mealLetter, sku)}
                                        >
                                            {sku.split('_')[0]}
                                        </div>
                                    )
                            )
                        }
                        </div>
                        <FactorySkuList
                            getPalletCountPerSku={getPalletCountPerSku}
                            skusByCategory={productionBatches}
                            selectedSkusToPrint={selectedSkusToPrint}
                        />
                    </Fragment>
                )}
            </Fragment>
        )
    }
}

export default FactoryLabelAutomatic;
