import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ellipsis from '../resources/images/png/menu.png';
import { cities, getCity } from "../services/cities";

const base = 'header';

@inject('HeaderStore', 'UserStore')
@observer
class Header extends Component {
  componentDidMount = () => {
    const { location, HeaderStore: { setPage, pages } } = this.props;

    pages.forEach(page => {
      if(location.pathname.includes(page)) {
        setPage(page);
        return;
      }
    })
  }

  handleSetPage = (page) => {
    const { history, HeaderStore: { setPage } } = this.props;

    setPage(page);
    history.push(`/${page}`);
  }

  render() {
    const {
      UserStore: { logout },
      HeaderStore: { pages, currentPage }
    } = this.props;
    const city = cities[getCity()];

    return(
      <div className={`${base} bg--grey`}>
        <div className={`${base}__container prl pll display--flex`}>
          <div className={`${base}__title`}>
            <h3 className="text--white fs--medium">Factory Production • { city }</h3>
          </div>
          <div className={`display--flex flex-align-items-center`}>
            <div className="pxs">
              <button className={`${base}__dropdown mrl btn btn-small text--capitalize`}>
                  {currentPage}
                  <svg fill="#fff" className="iconmoon-icon"><use xlinkHref={`#icon-caret-down`} /></svg>
                  <div className="dropdown-content text--capitalize">
                    {pages.filter(page => page !== currentPage).map((page, i) => 
                      <a key={i} className="cursor-pointer" onClick={() => this.handleSetPage(page)}>{page}</a>
                    )}
                  </div>
              </button>
            </div>
            <div className={`${base}__dropdown text--white`}>
              <img className={`${base}__ellipsis`} src={ellipsis} alt="ellipsis icon"></img>
              <div className="dropdown-content">
                <a className="cursor-pointer" onClick={() => logout()}>Sign out</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Header);