import React from 'react';

export default () => {
    return (
        <svg version="1.1" style={{ display: 'none' }}>
            <symbol id="icon-chevron-thin-up" viewBox="0 0 20 20">
                <path d="M2.582 13.891c-0.272 0.268-0.709 0.268-0.979 0s-0.271-0.701 0-0.969l7.908-7.83c0.27-0.268 0.707-0.268 0.979 0l7.908 7.83c0.27 0.268 0.27 0.701 0 0.969s-0.709 0.268-0.978 0l-7.42-7.141-7.418 7.141z"></path>
            </symbol>
            <symbol id="icon-chevron-thin-down" viewBox="0 0 20 20">
                <path d="M17.418 6.109c0.272-0.268 0.709-0.268 0.979 0s0.271 0.701 0 0.969l-7.908 7.83c-0.27 0.268-0.707 0.268-0.979 0l-7.908-7.83c-0.27-0.268-0.27-0.701 0-0.969s0.709-0.268 0.979 0l7.419 7.141 7.418-7.141z"></path>
            </symbol>
            <symbol id="icon-chevron-thin-right" viewBox="0 0 20 20">
                <path d="M13.25 10l-7.141-7.42c-0.268-0.27-0.268-0.707 0-0.979 0.268-0.27 0.701-0.27 0.969 0l7.83 7.908c0.268 0.271 0.268 0.709 0 0.979l-7.83 7.908c-0.268 0.271-0.701 0.27-0.969 0s-0.268-0.707 0-0.979l7.141-7.417z"></path>
            </symbol>
            <symbol id="icon-chevron-thin-left" viewBox="0 0 20 20">
                <path d="M13.891 17.418c0.268 0.272 0.268 0.709 0 0.979s-0.701 0.271-0.969 0l-7.83-7.908c-0.268-0.27-0.268-0.707 0-0.979l7.83-7.908c0.268-0.27 0.701-0.27 0.969 0s0.268 0.709 0 0.979l-7.141 7.419 7.141 7.418z"></path>
            </symbol>
            <symbol id="icon-chevron-double-right" viewBox="0 0 20 20">
                <path fillRule="evenodd" clipRule="evenodd" d="M9,8c0-0.28-0.11-0.53-0.29-0.71l-4-4C4.53,3.11,4.28,3,4,3C3.45,3,3,3.45,3,4c0,0.28,0.11,0.53,0.29,0.71L6.59,8l-3.29,3.29C3.11,11.47,3,11.72,3,12c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29l4-4C8.89,8.53,9,8.28,9,8z M13.71,7.29l-4-4C9.53,3.11,9.28,3,9,3C8.45,3,8,3.45,8,4c0,0.28,0.11,0.53,0.29,0.71L11.59,8l-3.29,3.29C8.11,11.47,8,11.72,8,12c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29l4-4C13.89,8.53,14,8.28,14,8C14,7.72,13.89,7.47,13.71,7.29z"/>
            </symbol>
            <symbol id="icon-chevron-double-left" viewBox="0 0 20 20">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.41,8l3.29-3.29C7.89,4.53,8,4.28,8,4c0-0.55-0.45-1-1-1C6.72,3,6.47,3.11,6.29,3.29l-4,4C2.11,7.47,2,7.72,2,8c0,0.28,0.11,0.53,0.29,0.71l4,4C6.47,12.89,6.72,13,7,13c0.55,0,1-0.45,1-1c0-0.28-0.11-0.53-0.29-0.71L4.41,8z M9.41,8l3.29-3.29C12.89,4.53,13,4.28,13,4c0-0.55-0.45-1-1-1c-0.28,0-0.53,0.11-0.71,0.29l-4,4C7.11,7.47,7,7.72,7,8c0,0.28,0.11,0.53,0.29,0.71l4,4C11.47,12.89,11.72,13,12,13c0.55,0,1-0.45,1-1c0-0.28-0.11-0.53-0.29-0.71L9.41,8z"/>
            </symbol>
            <symbol id="icon-cross" viewBox="0 0 24 24">
                <path d="M19 4q0.43 0 0.715 0.285t0.285 0.715q0 0.422-0.289 0.711l-6.297 6.289 6.297 6.289q0.289 0.289 0.289 0.711 0 0.43-0.285 0.715t-0.715 0.285q-0.422 0-0.711-0.289l-6.289-6.297-6.289 6.297q-0.289 0.289-0.711 0.289-0.43 0-0.715-0.285t-0.285-0.715q0-0.422 0.289-0.711l6.297-6.289-6.297-6.289q-0.289-0.289-0.289-0.711 0-0.43 0.285-0.715t0.715-0.285q0.422 0 0.711 0.289l6.289 6.297 6.289-6.297q0.289-0.289 0.711-0.289z"></path>
            </symbol>
            <symbol id="icon-print" viewBox="0 0 16 16">
		        <path fillRule="evenodd" clipRule="evenodd" d="M12,2.02c0-0.55-0.45-1-1-1H5c-0.55,0-1,0.45-1,1v1h8V2.02z M15,4.02H1c-0.55,0-1,0.45-1,1v6c0,0.55,0.45,1,1,1h1v-3h12v3h1c0.55,0,1-0.45,1-1v-6C16,4.46,15.55,4.02,15,4.02z M14,7.02h-2v-1h2V7.02zM11,13.02H5v-3H3v4c0,0.55,0.45,1,1,1h8c0.55,0,1-0.45,1-1v-4h-2V13.02z"/>
            </symbol>
            <symbol id="icon-calendar" viewBox="0 0 16 16">
                <path d="M11,3 C11.6,3 12,2.5 12,2 L12,1 C12,0.4 11.6,0 11,0 C10.4,0 10,0.4 10,1 L10,2 C10,2.5 10.4,3 11,3 Z M14,1 L13,1 L13,2 C13,3.1 12.1,4 11,4 C9.9,4 9,3.1 9,2 L9,1 L6,1 L6,2 C6,3.1 5.1,4 4,4 C2.9,4 2,3.1 2,2 L2,1 L1,1 C0.4,1 0,1.5 0,2 L0,14 C0,14.6 0.4,15 1,15 L14,15 C14.6,15 15,14.6 15,14 L15,2 C15,1.4 14.5,1 14,1 Z M5,13 L2,13 L2,10 L5,10 L5,13 Z M5,9 L2,9 L2,6 L5,6 L5,9 Z M9,13 L6,13 L6,10 L9,10 L9,13 Z M9,9 L6,9 L6,6 L9,6 L9,9 Z M13,13 L10,13 L10,10 L13,10 L13,13 Z M13,9 L10,9 L10,6 L13,6 L13,9 Z M4,3 C4.6,3 5,2.5 5,2 L5,1 C5,0.4 4.6,0 4,0 C3.4,0 3,0.4 3,1 L3,2 C3,2.5 3.4,3 4,3 Z" id="Shape" fill="#000000" fillRule="nonzero"></path>
            </symbol>
            <symbol id="icon-intersection" viewBox="0 0 16 16">
                <path fillRule="evenodd" clipRule="evenodd" d="M10,3C9.08,3,8.24,3.26,7.5,3.69C6.76,3.26,5.92,3,5,3C2.24,3,0,5.24,0,8s2.24,5,5,5c0.92,0,1.76-0.26,2.5-0.69C8.24,12.74,9.08,13,10,13c2.76,0,5-2.24,5-5S12.76,3,10,3z M5.9,10.85C5.61,10.94,5.31,11,5,11c-1.66,0-3-1.34-3-3s1.34-3,3-3c0.31,0,0.61,0.06,0.9,0.15C5.33,5.96,5,6.94,5,8C5,9.06,5.33,10.04,5.9,10.85z M10,11c-0.31,0-0.61-0.06-0.9-0.15C9.67,10.04,10,9.06,10,8c0-1.06-0.33-2.04-0.9-2.85C9.39,5.06,9.69,5,10,5c1.66,0,3,1.34,3,3S11.66,11,10,11z"/>
            </symbol>
            <symbol id="icon-arrow-top-right" viewBox="0 0 16 16">
                <path fillRule="evenodd" clipRule="evenodd" d="M13,2H6C5.45,2,5,2.45,5,3c0,0.55,0.45,1,1,1h4.59l-8.29,8.29C2.11,12.47,2,12.72,2,13c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29L12,5.41V10c0,0.55,0.45,1,1,1s1-0.45,1-1V3C14,2.45,13.55,2,13,2z"/>
            </symbol>
            <symbol id="icon-caret-down" viewBox="0 0 16 16">
                <path fillRule="evenodd" clipRule="evenodd" d="M12,6.5C12,6.22,11.78,6,11.5,6h-7C4.22,6,4,6.22,4,6.5c0,0.13,0.05,0.24,0.13,0.33c0,0,0,0,0,0l3.5,4l0,0C7.72,10.93,7.85,11,8,11s0.28-0.07,0.37-0.17l0,0l3.5-4l0,0C11.95,6.74,12,6.63,12,6.5z"/>
            </symbol>
            <symbol id="icon-check-circle" width="25" height="28" fill="none" viewBox="0 0 42 42">
                <g clip-path="url(#prefix__clip0)">
                    <circle cx="21.104" cy="20.604" r="20.604" fill="#18D665"/>
                    <path fill="#fff" d="M9.95 18.302l9.079 8.678 1.902-1.819-9.078-8.677-1.903 1.818z"/>
                    <path fill="#fff" d="M19.029 26.98l14.46-13.822-1.903-1.818-14.46 13.821 1.902 1.819z"/>
                </g>
                <defs>
                    <clipPath id="prefix__clip0">
                        <path fill="#fff" d="M0 0H41.209V41.209H0z" transform="translate(.5)"/>
                    </clipPath>
                </defs>
            </symbol>
        </svg>
    );
};