import React from 'react'
import ReactLoading from 'react-loading'


const Loading = (props) => {
  return (
    <div className="loading">
      <div className="loading-container">
        <ReactLoading type="spin" color="white" height={"10%"} width={"10%"} className="loader"/>
      </div>
    </div>
  )
}

export default Loading