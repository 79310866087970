import { observable, action } from "mobx";

const { BrowserPrint } = window;

class PrintStore {
    @observable isPrinterConnected = false;

    getPrinterStatus = () => {
        BrowserPrint.getLocalDevices(
            device_list => {
                //Add device to list of devices and to html select element
                if (device_list.length) {
                    this.isPrinterConnected = true;
                } else {
                    this.isPrinterConnected = false;
                }
            },
            undefined,
            "printer"
          );
    }

    // functions to print
    writeToSelectedPrinter(dataToWrite) {
        console.log(dataToWrite);
        window.selected_device.send(dataToWrite, undefined, this.errorCallback);
    }

    errorCallback() {
        alert("Printer not connected. Check printer settings and/or refresh the page");
    }
}

const store = new PrintStore();
export default store;
