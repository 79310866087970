import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import FactoryLabelAutomatic from './FactoryLabelAutomatic';
import InventoryBatch from './InventoryBatch';
import PrintStickerModal from './PrintStickerModal';
import Loading from '../Loading';

@inject('NewFactoryStore')
@observer
class FactoryLabel extends Component {
    state = {
        isCreateBatch: false,
        batch: null,
        isPrintModalOpen: false
    }

    toggleCreateBatchWindow = () => {
        this.setState(prevState => ({
            isCreateBatch: !prevState.isCreateBatch,
            batch: null
        }));
    }

    openEditBatchWindow = (batch) => {
        this.setState(prevState => ({
            isCreateBatch: true,
            batch: batch
        }));
    }

    setPrintModalState = (state) => {
        this.setState(prevState => ({
            isPrintModalOpen: state
        }));
    }

	render() {
        const { isCreateBatch, batch, isPrintModalOpen } = this.state;
        const { NewFactoryStore: { loading } } = this.props;

        if (loading) {
            return <Loading />
        }
        
        return (
            <Fragment>
                <h1 className="mtxl">Factory Label</h1>
                <button className="btn mlxs" onClick={this.toggleCreateBatchWindow}>
                    {isCreateBatch ? 'Go back' : 'Create Batch'}
                </button>

                {isCreateBatch ? (
                    <InventoryBatch batch={batch}
                                    toggleCreateBatchWindow={this.toggleCreateBatchWindow}/>
                ) : (
                    <FactoryLabelAutomatic
                        openEditBatchWindow={this.openEditBatchWindow}
                        setPrintModalState={this.setPrintModalState}
                    />
                )}

                {isPrintModalOpen ? (
                    <PrintStickerModal setPrintModalState={this.setPrintModalState}/>
                ) : null}

            </Fragment>
        )
    }
}

export default FactoryLabel;
