import React, {Component, Fragment} from 'react';
import {inject, observer} from 'mobx-react';
import QrReader from 'react-qr-reader';
import moment from 'moment';
import Switch from 'react-toggle-switch'

const audio = new Audio(`${process.env.PUBLIC_URL}/qrcode_scan_sound.mp3`);

@inject('QRCodeStore')
@observer
class QRCodeScanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            meal_count_in_pallet: '',
            meals_per_pallet: '',
            last: false,
            showConfirmationLastStickerModal: false,
            showRequestAudioPlayPermission: true,
            processing: false,
            isAutoSubmit: true
        }
    }

    handleScan = data => {
        const {QRCodeStore: {handleSetResult}} = this.props;
        const { processing, isAutoSubmit } = this.state;
        // Block next scan if browser is busy
        if (processing) {
            return;
        }

        if (data) {
            this.setState({
                processing: true
            });

            console.log(data)
            this.playAudio();
            const qrCode = data.split('-');
            const sku = qrCode[0];
            const production_date = moment(qrCode[1], 'DDMMYYYY').format('DD-MM-YY');
            const expiry_date = moment(qrCode[2], 'DDMMYYYY').format('DD-MM-YY');
            let mealsCountInAndPerPallet = qrCode[3].split('/');
            const meal_count_in_pallet = +mealsCountInAndPerPallet[0];
            const meals_per_pallet = +mealsCountInAndPerPallet[1];
            const unique_pallet_count = +qrCode[4];
            const total_pallet = +qrCode[5];
            const batch_id = qrCode[6];
            handleSetResult({
                sku,
                production_date,
                expiry_date,
                meal_count_in_pallet,
                unique_pallet_count,
                total_pallet,
                batch_id
            })
            this.setState({
                meal_count_in_pallet: meal_count_in_pallet,
                meals_per_pallet: meals_per_pallet,
                last: unique_pallet_count === total_pallet
            });

            if (isAutoSubmit) {
                this.handleSubmit(false);
            }
        }
    }

    playAudio() {
        let promise = audio.play();
        if (promise !== undefined) {
            promise.catch(error => {
                // Auto-play was prevented
                // Show a UI element to let the user manually start playback
            }).then(() => {
                // Auto-play started
            });
        }
    }

    handleError = err => {
        console.error(err)
    }

    handleChange = (e) => {
        const {value} = e.target;
        this.setState({meal_count_in_pallet: value});
    }

    toggleAutoSubmit = (e) => {
        this.setState(prevState => {
            return {
                isAutoSubmit: !prevState.isAutoSubmit
            };
        });
    }

    handleSubmit = (last = false) => {
        const {meal_count_in_pallet, isAutoSubmit} = this.state;
        const {QRCodeStore: {handleScanBarcode, result, scanMode}} = this.props;

        handleScanBarcode({
            ...result,
            ...(scanMode === 'loose' && meal_count_in_pallet && {meal_count_in_pallet: +meal_count_in_pallet}),
            last
        }, () => {
            if (isAutoSubmit) {
                // Wait 1.5s before next scan
                setTimeout(() => {this.setState({processing: false})}, 1500);
            } else {
                this.setState({processing: false});
            }

        });
        this.setState({showConfirmationLastStickerModal: false});
    }

    handleShowConfirmationLastStickerModal = () => {
        const {
            QRCodeStore: {handleGetBatchById, result}
        } = this.props;
        handleGetBatchById(result.sku, result.batch_id, () => {
            this.setState({showConfirmationLastStickerModal: true})
        });
    }

    handleCloseConfirmationLastStickerModal = (e) => {
        e.preventDefault();
        const {
            QRCodeStore: {handleSetScanMode, handleSetResult}
        } = this.props;
        this.setState({
            showConfirmationLastStickerModal: false,
            processing: false
        });
        handleSetScanMode(undefined);
        handleSetResult(null);
    }

    getTotalScannedQuantity = (batch, submittingQty, currentIndex) => {
        if (!batch) {
            return 0;
        }
        if (!batch.barcode_tracker) {
            return submittingQty;
        }
        let total = 0;
        let currentIndexScanned = false;
        batch.barcode_tracker.forEach(entry => {
            total += entry.quantity;
            if (entry.unique_pallet_count == currentIndex) {
                currentIndexScanned = true;
            }
        });
        if (!currentIndexScanned) {
            total += submittingQty;
        }
        return total;
    }

    getRemainingStickerIndex = (batch, currentIndex) => {
        if (!batch) {
            return [];
        }
        const {QRCodeStore: {result}} = this.props;
        const totalPallet = result.total_pallet;
        let remaining = Array.from(new Array(totalPallet), (x, i) => i + 1);
        if (batch.barcode_tracker) {
            batch.barcode_tracker.forEach(entry => {
                const index = remaining.indexOf(entry.unique_pallet_count);
                if (index > -1) {
                    remaining.splice(index, 1);
                }
            });
        }

        const index = remaining.indexOf(currentIndex);
        if (index > -1) {
            remaining.splice(index, 1);
        }
        return remaining;
    }

    getDisplayRemainingIndex = (batch, currentIndex) => {
        const remaining = this.getRemainingStickerIndex(batch, currentIndex);
        if (!remaining.length) {
            return '';
        }
        if (remaining.length > 10) {
            return remaining.length + " boxes remaining";
        }
        let text = "Missing ";
        remaining.forEach(index => {
            const palletNo = ("" + index).padStart(3, '0');
            text += palletNo + " ";
        })
        return text;
    }

    render() {
        const {
            meal_count_in_pallet,
            meals_per_pallet,
            last,
            showConfirmationLastStickerModal,
            showRequestAudioPlayPermission,
            processing,
            isAutoSubmit
        } = this.state;
        const {
            QRCodeStore: {handleSetScanMode, result, scanMode, batchToComplete}
        } = this.props;

        return (
            <Fragment>
                <div className={"qr-reader " + `${processing ? 'success' : ''}`}>
                    <QrReader
                        delay={500}
                        onError={this.handleError}
                        onScan={this.handleScan}
                        facingMode="environment"
                        style={{width: '100%'}}
                    />
                </div>
                <div className="ms">
                    {!scanMode && (
                        <Fragment>
                            <div>
                                <Switch className={"mrm "} onClick={this.toggleAutoSubmit} on={isAutoSubmit}/>
                                <label className="form-label">Auto Submit</label>
                            </div>
                            <hr/>
                            <button
                                className={"btn mrm " + `${meals_per_pallet === meal_count_in_pallet ? 'btn-selected' : ''}`}
                                disabled={!result || isAutoSubmit}
                                onClick={() => this.handleSubmit(false)}>
                                {result && meals_per_pallet !== meal_count_in_pallet ? 'Pallet number ' + result.unique_pallet_count : ''}
                                {result && meals_per_pallet !== meal_count_in_pallet ? <br/> : ''}
                                Submit & Next
                            </button>
                            <button
                                className={"btn mrm " + `${meals_per_pallet !== meal_count_in_pallet ? 'btn-selected' : ''}`}
                                disabled={!result || isAutoSubmit}
                                onClick={() => handleSetScanMode('loose')}>
                                Loose
                            </button>
                            <button className="btn btn-selected"
                                    disabled={!result || isAutoSubmit}
                                    onClick={() => this.handleShowConfirmationLastStickerModal()}>
                                Submit & Finish
                            </button>
                        </Fragment>
                    )}
                    {scanMode === 'loose' && (
                        <Fragment>
                            <h2>How many loose meals?</h2>
                            <input
                                className="border--rounded border--solid fs--medium-medium-large text--center text--black text--bolder width--100px ps mm"
                                type="number"
                                pattern="[0-9]*"
                                value={meal_count_in_pallet}
                                onChange={this.handleChange}
                            />
                            <Fragment>
                                <h3>Pallet number {result ? result.unique_pallet_count : ''}</h3>
                                <button className="btn btn-selected width--100 mtm mbm"
                                        onClick={() => this.handleSubmit(false)}>
                                    Submit {meal_count_in_pallet} meals & Next
                                </button>
                                <button className="btn btn-selected width--100 mtm mbm"
                                        onClick={() => this.handleShowConfirmationLastStickerModal()}>
                                    Submit {meal_count_in_pallet} meals & Finish
                                </button>
                            </Fragment>

                        </Fragment>
                    )}
                    {showConfirmationLastStickerModal &&
                    <Fragment>
                        <div className="print-modal">
                            <div className="print-modal__content">
                                <div>
                                    <span className="print-modal__close"
                                          onClick={this.handleCloseConfirmationLastStickerModal}>&times;</span>
                                    <h3>Scanned
                                        quantity: {batchToComplete ? this.getTotalScannedQuantity(batchToComplete, scanMode === 'loose' ? meal_count_in_pallet : result.meal_count_in_pallet, result.unique_pallet_count) : ''}</h3>
                                    <h3>{this.getDisplayRemainingIndex(batchToComplete, result.unique_pallet_count)}</h3>
                                </div>
                                <div>
                                    <button className="btn mrm"
                                            onClick={this.handleCloseConfirmationLastStickerModal}>Cancel
                                    </button>
                                    <button className="btn btn-primary"
                                            onClick={() => this.handleSubmit(true)}>Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Fragment>}
                    {showRequestAudioPlayPermission &&
                    <Fragment>
                        <div className="print-modal">
                            <div className="print-modal__content">
                                <div>
                                    <span className="print-modal__close" onClick={this.closeModal}>&times;</span>
                                    <h1>Allow this website to play sound?</h1>
                                </div>
                                <div>
                                    <button className="btn btn-primary"
                                            onClick={() => {
                                                this.playAudio();
                                                this.setState({showRequestAudioPlayPermission: false});
                                            }}>Allow
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Fragment>}
                </div>
            </Fragment>
        );
    }
}

export default QRCodeScanner;
