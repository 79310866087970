import { observable, action, toJS } from "mobx";
import moment from 'moment';
import * as api from '../services/api';
import PrintStore from './PrintStore';

class FactoryStore {
    @observable loading = false;
    @observable productionDate = moment();
    @observable dateRange = [null, null];
    @observable productionBatches = null;
    @observable selectedSkusToPrint = null;
    @observable skus = null;
    @observable unitSkus;
    @observable selectedUnitSku;
    @observable selectedUnitSkuName;
    @observable productionDate = moment().format('DD-MM-YYYY');
    @observable expirationTime = null;
    @observable uomQuantity = null;
    @observable expirationDate = null;

    @action
    handleGetUnitSkus = (callback) => {
        api.getUnitSkus()
            .then(res => {
                this.unitSkus = res
                    .filter(u => u.packaging && u.packaging.length > 0)
                    .map(u => {
                        return { value: u.id, label: u.sku, subComponent: u.sub_component, name : u.name };
                    });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => console.log(error));
    };

    @action setSelectedUnitSku = (unitSku) => {
        this.selectedUnitSku = unitSku;
        this.selectedUnitSkuName = unitSku.name;
        api.getComponentDetails(unitSku.label)
        .then(res => {
            this.expirationTime = res.expiration_time;
            this.uomQuantity = res.uom_quantity
        })
        .catch(err => console.log(err));
    };

    @action handleSetDate = (date) => {
        this.productionDate = date;
    }

    @action handleExpiryDate = (date) => {
        this.expirationDate = date;
    }

    @action handleGetSkus = () => {
        this.loading = true;
        api.getSkus()
        .then(res => {
            this.skus = res;
        })
        .then(() => this.handleGetProductionBatches())
        .catch(err => console.log(err));
    }

    getPalletCountPerSku = (mealLetter, sku) => {
        return this.productionBatches[mealLetter][sku].totalPallets;
    }

    @action handleSetProductionDate = (productionDate) => {
        this.productionDate = productionDate;
    }

    @action handleSetDateRange = (dateRange) => {
        this.dateRange = dateRange;
    }

    @action handleGetProductionBatches = () => {
        const from_date = moment(this.dateRange[0]).format('DD-MM-YYYY');
        const to_date = moment(this.dateRange[1]).add(1, 'days').format('DD-MM-YYYY')

        api.getProductionBatches(from_date, to_date)
            .then(res => {
                this.productionBatches = res.batches.reduce((acc, cur) => {
                    const {
                        color: sku,
                        sourced_quantity,
                    } = cur;
                    
                    if (sourced_quantity) {
                        const mealLetter = sku.charAt(0);
                        const skuData = this.skus.find(item => item.sku === sku);
                        const { component_names, meals_per_pallet } = skuData

                        const mealComponentParts = component_names ? component_names.split(' ') : [];
                        const breakpoint = Math.ceil(mealComponentParts.length / 2);
                        const mealComponentLine1 = mealComponentParts.slice(0, breakpoint).join(' ');
                        const mealComponentLine2 = mealComponentParts.slice(breakpoint).join(' ');

                        const totalPallets = meals_per_pallet ? Math.ceil(sourced_quantity / meals_per_pallet) : 0;

                        acc[mealLetter] = acc[mealLetter] || {};
                        acc[mealLetter][sku] = acc[mealLetter][sku] || cur;
            
                        acc[mealLetter][sku]['totalPallets'] = totalPallets;
                        acc[mealLetter][sku]['mealComponentLine1'] = mealComponentLine1;
                        acc[mealLetter][sku]['mealComponentLine2'] = mealComponentLine2;
                    }

                    return acc;
                }, {});
        
                this.selectedSkusToPrint = res.batches.reduce((acc, cur) =>{
                    const { color: sku, sourced_quantity } = cur;
                    const mealLetter = sku.charAt(0);

                    if (sourced_quantity) {
                        acc[mealLetter] = acc[mealLetter] ? acc[mealLetter].concat([sku]) : [sku];
                    }

                    return acc;
                }, {})
        
                this.loading = false;
            })
            .catch(err => console.log(err));
    }

    @action setSelectedSkusToPrint = (mealLetter, selectedSku) => {
        if (this.selectedSkusToPrint[mealLetter].includes(selectedSku)) {
          this.selectedSkusToPrint[mealLetter] = this.selectedSkusToPrint[mealLetter].filter(sku => sku !== selectedSku);
        } else {
          this.selectedSkusToPrint[mealLetter].push(selectedSku);
          this.selectedSkusToPrint[mealLetter].sort();
        }
    }

    getFactoryLabelPrintString = ({
        batchDate,
        mealComponentLine1,
        mealComponentLine2,
        mealComponentLine3,
        productionDate,
        sku,
    }) => {
        return `
        ^XA
        ^FX Middle Divider
        ^FO390,2^GB0,200,3^FS
        ^FX Horizontal Dividers
        ^FO0,70^GB850,0,3^FS
        ^FO0,135^GB850,0,3^FS
        ^FO0,200^GB850,0,3^FS
        ^FT45,55^A0N,40,40^FB400,1,0,L^FH\\^FDPRODUCTION DATE^FS
        ^FT45,120^A0N,40,40^FB400,1,0,L^FH\\^FDEXPIRY DATE^FS
        ^FT45,180^A0N,40,40^FB400,1,0,L^FH\\^FDCOMPONENT CODE^FS
            
        ^FT405,55^A0N,40,40^FB400,1,0,L^FH\\^FD${productionDate}^FS
        ^FT405,120^A0N,40,40^FB400,1,0,L^FH\\^FD${batchDate}^FS
        ^FT405,180^A0N,40,40^FB500,1,0,L^FH\\^FD${sku.replace(/_/g, '-')}^FS
        ^FT130,250^A0N,40,40^FB600,1,0,C^FH\\^FD${mealComponentLine1}^FS
        ^FT130,300^A0N,40,40^FB600,1,0,C^FH\\^FD${mealComponentLine2}^FS
        ^FT130,350^A0N,40,40^FB600,1,0,C^FH\\^FD${mealComponentLine3}^FS
                
        ^XZ`
    }

    getManualFactoryLabelPrintString = () => {
        let mealComponentLine1 = '';
        let mealComponentLine2 = '';
        let mealComponentLine3 = '';
        let totalLines = 1;
        let mealNameLine = '';
        if (this.selectedUnitSku.name != null) {
            let itemNameParts = this.selectedUnitSku.name.replaceAll("[\\uE000-\\uF8FF]|\\uD83C[\\uDF00-\\uDFFF]|\\uD83D[\\uDC00-\\uDDFF]", "").split(" ")
            for (const itemNamePart of itemNameParts) {
                if (mealNameLine.length == 0) {
                    mealNameLine = itemNamePart;
                    mealComponentLine1 = mealNameLine;
                    continue;
                } else {
                    let length = mealNameLine.length + itemNamePart.length;
                    if (length <= 18) {
                        mealNameLine = mealNameLine.concat(" ").concat(itemNamePart);
                        if (totalLines == 1) {
                            mealComponentLine1 = mealNameLine;
                        } else if (totalLines == 2) {
                            mealComponentLine2 = mealNameLine;
                        } else {
                            mealComponentLine3 = mealNameLine
                        }
                        continue;
                    }
                }
                mealNameLine = itemNamePart;
                totalLines += 1;
                if (totalLines == 1) {
                    mealComponentLine1 = mealNameLine;
                } else if (totalLines == 2) {
                    mealComponentLine2 = mealNameLine;
                } else {
                    mealComponentLine3 = mealNameLine
                }
                if (totalLines > 3) {
                    break;
                }
            } 
        }
        return `
        ^XA
        ^FX Middle Divider
        ^FO390,2^GB0,265,3^FS

        ^FX Horizontal Dividers
        ^FO0,70^GB850,0,3^FS
        ^FO0,135^GB850,0,3^FS
        ^FO0,200^GB850,0,3^FS
        ^FO0,265^GB850,0,3^FS

        ^FT45,55^A0N,40,40^FB400,1,0,L^FH\^FDPRODUCTION DATE^FS
        ^FT45,120^A0N,40,40^FB400,1,0,L^FH\^FDEXPIRY DATE^FS
        ^FT45,180^A0N,40,40^FB400,1,0,L^FH\^FDUOM QUANTITY^FS
        ^FT45,240^A0N,40,40^FB400,1,0,L^FH\^FDCOMPONENT CODE^FS
            
        ^FT405,55^A0N,40,40^FB400,1,0,L^FH\^FD${this.productionDate}^FS
        ^FT405,120^A0N,40,40^FB400,1,0,L^FH\^FD${this.expirationDate}^FS
        ^FT405,180^A0N,40,40^FB500,1,0,L^FH\^FD${this.uomQuantity}^FS
        ^FT405,240^A0N,40,40^FB500,1,0,L^FH\^FD${this.selectedUnitSku.label.replace(/_/g, '-')}^FS

        ^FT130,315^A0N,40,40^FB600,1,0,C^FH\^FD${mealComponentLine1}^FS
        ^FT130,365^A0N,40,40^FB600,1,0,C^FH\^FD${mealComponentLine2}^FS
        ^FT130,415^A0N,40,40^FB600,1,0,C^FH\^FD${mealComponentLine3}^FS
                
        ^XZ`
    }

    @action createFactoryPrintZplManual = (print, printData) => {
        let printString = "";
    
        for (let i = 0; i < printData.total; i++) {
            printString += this.getManualFactoryLabelPrintString(printData);
        }
    
        if (print) {
            console.log(printString);
            PrintStore.writeToSelectedPrinter(printString);
        }
    }

    @action createFactoryPrintZplAuto = (print) => {
        let printString = "";
    
        Object.keys(this.selectedSkusToPrint)
            .sort()
            .forEach(mealLetter => {
                const skus= this.selectedSkusToPrint[mealLetter];
                skus
                    .sort()
                    .forEach(sku => {
                        const skuData = this.productionBatches[mealLetter][sku]
                        const { 
                            mealComponentLine1,
                            mealComponentLine2,
                            totalPallets,
                            arrival_at,
                            expires_at,
                        } = skuData;
        
                        const productionDate = this.productionDate.format('DD/M')
                        const arrivalDateMoment = moment(arrival_at, 'x');
                        const expiryDateMoment = moment(expires_at, 'x').subtract(1, "days");
                        const arrivalDate = arrivalDateMoment.format('DD/M');
                        const expiryDate = expiryDateMoment.format('DD/M');
                        const batchDate = `${arrivalDate} - ${expiryDate}`;
        
                        const printData = {
                            batchDate,
                            mealComponentLine1,
                            mealComponentLine2,
                            productionDate,
                            sku,
                        }

                        printString += `
                        CT~~CD,~CC^~CT~
                        ^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR4,4~SD15^JUS^LRN^CI0^XZ
                        ^XA
                        ^MMT
                        ^PW1181
                        ^LL0591
                        ^LS0
                        ^FO512,32^GFA,01536,01536,00012,:Z64:eJztkzEOgzAQBC+icBPJP4CPIPEznKfxFJ5AmQKxuNrbNJEDlhBSrhpZ1s5tcWb3mqdwKzwov5zT5IyZ+MBCbvAmB6zkCCgztAMYOsh7kv/A5qoPZn4D9wb4PlnFPb+oCrQlql+1BarqDXGqoaoqNVTVmYaiqtVQVUcbmqjyf+dOribKNQVX5T2pyqHmswmPwr3957rZAVuQ0R4=:6616
                        ^FO576,32^GFA,01152,01152,00012,:Z64:eJzVkzEKxDAMBCVcuAn4B+en+GnJ0/KUe0LKK0I2rrQbSBEOEu5UDUbsWAKZ/WMNwi/hJjxOZMyBjndwEs5Yggs+wRUr47ExHmA8MMn7zH4KKigooCCDggQKOofAVQARjCJoIqgiKCLIDwhOp3HcvK5Lgl9c1yXB6TRfrCvYhU36u4Bn0OQ8qpxQ4X86B1rmdVhivDnjzRY71A7lmdnk:EFDB
                        ^FT241,464^A0N,208,206^FB703,1,0,C^FH\^FD${sku.replace(/_/g, '-')}^FS
                        ^FO580,46^GB0,170,18^FS
                        ^PQ1,0,1,Y^XZ
                        `
        
                        for (let i = 0; i < totalPallets; i++) {
                            printString += this.getFactoryLabelPrintString(printData);
                        }
                    })
            })

        if (print) {
            console.log(printString);
            PrintStore.writeToSelectedPrinter(printString);
        }
    }
}

const store = new FactoryStore();
export default store;
