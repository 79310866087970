import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';

const base = 'transfer-list'

@inject('OutboundStore')
@observer
class OutboundSkuList extends Component {
	handleOpenPrintModal = (sku, mealLetter, e) => {
		e.preventDefault();
		const {OutboundStore: {
			getSkuStickerList,
		}, setShowPrintModal} = this.props;

		getSkuStickerList(sku, mealLetter);
		setShowPrintModal(true);
	}
    render() {
			const { OutboundStore: {
				getPalletCountPerSku,
				hubStockTransfers,
				selectedSkusToPrint,
				getRemainingScanData
			}} = this.props;

			return (
				<div className={`${base} outbound`}>
					{selectedSkusToPrint
						.map(mealLetter => (
							<Fragment key={mealLetter}>
								<div className={`${base}__header outbound`}>{`Type ${mealLetter}`}</div>
								{Object.keys(hubStockTransfers[mealLetter])
									.sort()
									.map(sku => (
									<Fragment key={sku}>
										<div className={`${base}__meal-sku`}>{sku}</div>
										<button className={`btn`} onClick={(e) => this.handleOpenPrintModal(sku, mealLetter, e)}>{getPalletCountPerSku(mealLetter, sku)}</button>
										<div className={`${base}__meal-count`}>{getRemainingScanData(sku)}</div>
									</Fragment>
								))}
							</Fragment>
						))
					}
				</div>
			);
  	}
}

export default OutboundSkuList;
