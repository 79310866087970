import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import ReactFileReader from "react-file-reader";

@inject('ComponentStockStore')
@observer
class ComponentStock extends Component {
    handleFiles = files => {
        const { ComponentStockStore } = this.props;
        let formData = new FormData();
        formData.append("units_file", files[0])
        ComponentStockStore.sendComponentStockUpsertRequest(formData);
    }

    render() {
        return (
            <Fragment>
                <h1 className="mtxl">Add Component Stock</h1>
                <ReactFileReader handleFiles={this.handleFiles} fileTypes={'.csv'}>
                    <button className='btn btn-primary cursor-pointer width--300px'>Uploading </button>
                </ReactFileReader>
            </Fragment>
        )
    }
}

export default ComponentStock;