import { observable, action, toJS } from "mobx";
import moment from 'moment';
import * as api from '../services/api';

class QRCodeStore {
    @observable loading = false;
    @observable dateRange = [null, null];
    @observable productionBatches = null;
    @observable result = null;
    @observable scanMode = '';
    @observable palletNumber = 1;
    @observable batchToComplete = null;

    @action handleSetDateRange = (dateRange) => {
        this.dateRange = dateRange;
    }

    @action handleSetResult = (result) => {
        this.result = result;
    }

    @action handleSetScanMode = (scanMode) => {
        this.scanMode = scanMode;
    }

    @action handleScanBarcode = (data, callback) => {
        this.loading = true;
        console.log(data);
        api.scanBarcode(data)
            .then(res => {
                if (res.color === data.sku) {
                    this.result = null;
                    this.scanMode = '';
                }
                this.palletNumber++;
            })
            .then(() => {})
            .catch(err => {
                alert(err.message)
                console.log(err)
            })
            .finally(() => {
                if (callback) {
                    callback();
                }
            });
    }

    @action handleGetBatchById = (sku, batchId, callback) => {
        this.batchToComplete = null;
        api.getBatchById(sku, batchId)
            .then(res => res.json())
            .then(res => {
                if (!res.id && !res.success) {
                    alert(res.message);
                } else {
                    this.batchToComplete = res;
                    if (callback) {
                        callback();
                    }
                }
            });
    }
}

const store = new QRCodeStore();
export default store;
