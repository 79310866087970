import dev from './development/dahmakan'
import staging from './staging/dahmakan'
import prod from './production/dahmakan'
import common from "./common";

const parametersList = {
    dahmakan: {
        development: dev,
        staging: staging,
        production: prod
    }
}

// merging environment specific parameters with the common base. Any same properties are overwritten by the environment specific parameters.
let parameters = Object.assign(common, parametersList.dahmakan[process.env.REACT_APP_ENV || process.env.NODE_ENV])

export default {
    ...parameters
}