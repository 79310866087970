import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import Select from 'react-select';
import {DateInput} from "@blueprintjs/datetime";

@inject('NewFactoryStore', 'PrintStore')
@observer
class InventoryBatch extends Component {

    constructor(props) {
        super(props);
        const { batch } = this.props;
        const now = moment().startOf('day').toDate().getTime();
        if (batch) {
            this.state = {
                id: batch.id,
                sku: batch.sku || '',
                mealBatchId: batch.mealBatchId || '',
                componentBatchIds: batch.componentBatchIds || '',
                arrivalDate: batch.arrivalAt || now,
                expiryDate: batch.expiresAt || now,
                quantity: batch.mealCount || '',
                // Do not overwrite this flag
                status: null,
            };
        } else {
            this.state = {
                id: null,
                sku: '',
                mealBatchId: '',
                componentBatchIds: '',
                arrivalDate: now,
                expiryDate: now,
                quantity: '',
                status: null,
            };
        }
    }

    handleSkuChange = (e) => {
        this.setState({ sku: e.value });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleCheckboxChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked });
    }

    handleDateChange = (name, date) => {
        this.setState({ [name]: date.getTime() });
    }

    returnToMainScreen = () => {
        const { NewFactoryStore, toggleCreateBatchWindow } = this.props;
        NewFactoryStore.clearBatchesData();
        toggleCreateBatchWindow();
    }

    handleClickSave = e => {
        const { NewFactoryStore } = this.props;
        const fields = { ...this.state };
        e.preventDefault();
        NewFactoryStore.sendBatchUpsertRequest(fields, this.returnToMainScreen);
    };

    componentDidMount = () => {
        const { NewFactoryStore: {skus, handleGetSkuList} } = this.props;
        if (!skus) {
            handleGetSkuList();
        }
    }

    getSkuList = (skus) => {
        let skuList = [];
        if (!skus) {
            return skuList;
        }

        skus.forEach(sku => {
            skuList.push({value: sku.sku, label: sku.sku});
        })
        return skuList;
    }

    render() {
        let {
            sku,
            mealBatchId,
            componentBatchIds,
            arrivalDate,
            expiryDate,
            quantity
        } = this.state;
        const { NewFactoryStore: {skus} } = this.props;
        const selectCustomStyles = {
            control: (provided, state) => ({
                ...provided,
                fontWeight: 1000,
                fontSize: 16
            }),
            menu: (provided, state) => ({
                ...provided,
                zIndex: 2
            }),
        }
        return (
            <Fragment>
                <form className="form-grid">
                    <label className="form-label">SKU</label>
                    <Select options={this.getSkuList(skus)}
                            onChange={this.handleSkuChange}
                            value={{value: sku, label: sku}}
                            styles={selectCustomStyles}/>
                    <label className="form-label">Arrival Date</label>
                    <DateInput
                        formatDate={date => moment(date).format('DD/MM/YYYY')}
                        onChange={date => this.handleDateChange('arrivalDate', date)}
                        parseDate={str => moment(str, 'DD/MM/YYYY').toDate()}
                        placeholder="Pick a date"
                        value={moment(arrivalDate).toDate()}
                        inputProps={{ leftIcon: "calendar" }}
                        className="form-input date-input single full-size-field"
                        maxDate={new Date(2030,12,31)}
                    />
                    <label className="form-label">Expiry Date</label>
                    <DateInput
                        formatDate={date => moment(date).format('DD/MM/YYYY')}
                        onChange={date => this.handleDateChange('expiryDate', date)}
                        parseDate={str => moment(str, 'DD/MM/YYYY').toDate()}
                        placeholder="Pick a date"
                        value={moment(expiryDate).toDate()}
                        inputProps={{ leftIcon: "calendar" }}
                        className="form-input date-input single full-size-field"
                        maxDate={new Date(2030,12,31)}
                    />
                    <label className="form-label">Quantity</label>
                    <input
                        className="form-input border--rounded border--solid"
                        required
                        name="quantity"
                        type="number"
                        value={quantity}
                        onChange={this.handleChange}
                        placeholder="100"
                    />
                    <label className="form-label">Meal Batch ID</label>
                    <input
                        className="form-input border--rounded border--solid"
                        name="mealBatchId"
                        type="text"
                        value={mealBatchId}
                        onChange={this.handleChange}
                        placeholder="C14_005"
                    />
                    <label className="form-label">Meal Components (Comma separated)</label>
                    <input
                        className="form-input border--rounded border--solid"
                        name="componentBatchIds"
                        type="text"
                        value={componentBatchIds}
                        onChange={this.handleChange}
                        placeholder="Garlic Butter Rice_20210708b, Cheesy Chicken Chop_20210708b"
                    />

                    <button className="btn form-button" onClick={this.handleClickSave}>
                        <svg fill="#fff" className="iconmoon-icon"><use xlinkHref={`#icon-save`} /></svg>
                        Save
                    </button>
                </form>
            </Fragment>
        )
    }
}

export default InventoryBatch;
