import React, { Component, Fragment } from 'react';
import StockCheckHeader from './StockCheckHeader';
import SkuStockInput from './SkuStockInput';
import Loading from '../Loading';
import Modal from '../Modal';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

const base = 'sku-entry';

@inject('StockCheckStore')
@observer
class StockCheckUpdate extends Component {
    initialState = {
        openSkuList: false,
        isResubmit: false,
        showConfirmationModel: false,
        stockQuantity: {},
        errorMessage: '',
    };

    state = this.initialState;

    componentDidMount = () => {
        const {
            location: {
                state: { itemType },
            },
            StockCheckStore: {
                handleGetProductionBatches,
                handleSetItemType,
            },
        } = this.props;

        handleSetItemType(itemType);
        handleGetProductionBatches();
    };

    handleOpenSkuList = () => {
        this.setState({ openSkuList: true });
    };

    handleCloseSkuList = () => {
        this.setState({ openSkuList: false });
    };

    handleCloseConfirmationModal = () => {
        this.setState({ showConfirmationModel: false });
    };

    handleSetCurrentSku = (sku) => {
        const {
            StockCheckStore: { handleSetCurrentSku },
        } = this.props;

        handleSetCurrentSku(sku);
        this.setState(this.initialState);
    };

    handleGetStockInputs = (id, quantity) => {
        const { stockQuantity } = this.state;
        const updatedStockQuantity = {
            ...stockQuantity,
            [id]: quantity,
        };
        this.setState({ stockQuantity: updatedStockQuantity });
    };

    handleSubmit = () => {
        const { stockQuantity, isResubmit } = this.state;
        const {
            StockCheckStore: { productionBatches, currentSku, isSkuChecked },
        } = this.props;
        const batches = productionBatches[currentSku]['batches'];

        if (isSkuChecked(currentSku) && !isResubmit) {
            this.setState({ isResubmit: true });
        } else {
            let errorMessage = Object.entries(stockQuantity).reduce(
                (acc, [id, quantity]) => {
                    const batch = batches.find((batch) => batch.id === id);
                    const startDate = moment(batch.arrival_at, 'x').format(
                        'DD/MM'
                    );
                    const endDate = moment(batch.expires_at, 'x')
                        .subtract(1, 'days')
                        .format('DD/MM');
                    const batchDates = `${startDate} - ${endDate}`;

                    if (
                        quantity &&
                        Math.abs(quantity - batch.virtual_stock) > 0
                    ) {
                        acc.push({
                            id,
                            count: quantity,
                            virtualStock: batch.virtual_stock,
                            batchDates,
                        });
                    }
                    return acc;
                },
                []
            );

            if (errorMessage.length) {
                this.setState({ showConfirmationModel: true, errorMessage });
            } else {
                this.sendRequest();
            }
        }
    };

    sendRequest = async () => {
        const { stockQuantity } = this.state;
        const {
            StockCheckStore: {
                handleSetActualStock,
                productionBatches,
                currentSku,
            },
        } = this.props;
        const batches = productionBatches[currentSku]['batches'];
        const menuId = batches[0].menu_item_id;

        let requests = batches
            .map((b) => [b.id, stockQuantity[b.id]])
            .filter(([_id, quantity]) => quantity)
            .map(([id, quantity]) => ({
                id,
                quantity,
                menuId,
                production: false,
                refresh: false,
            }));
        requests[requests.length - 1].refresh = true;

        for (const request of requests) {
            await handleSetActualStock(request, request.refresh);
        }
        this.setState(this.initialState);
    };

    render() {
        const {
            openSkuList,
            stockQuantity,
            isResubmit,
            showConfirmationModel,
            errorMessage,
        } = this.state;
        const {
            StockCheckStore: {
                loading,
                productionBatches,
                currentSku,
                totalSkusChecked,
                totalSkus,
                skusByLetter,
                skusByCategory,
                isSkuChecked,
                lastUpdated,
                itemType,
            },
        } = this.props;

        if (loading || !currentSku) {
            return <Loading />;
        }

        const { batches } = productionBatches[currentSku];
        const itemName = batches[0].menu_item_name;
        const isChecked = isSkuChecked(currentSku);

        const batchIds = batches.map((batch) => batch.id);
        const hasInput =
            itemType === 'Item'
                ? batchIds.every(
                (id) =>
                    Object.keys(stockQuantity).includes(id) &&
                    stockQuantity[id]
                )
                : batchIds.some(
                (id) =>
                    Object.keys(stockQuantity).includes(id) &&
                    stockQuantity[id]
                );

        const skusByGroup = itemType === 'Item' ? skusByLetter : skusByCategory;

        return (
            <div className="mbxl pbxl">
                <StockCheckHeader title="Daily SKU Check" />
                <div
                    className={`${base}__progress fs--medium-medium-large text--bolder ps mbs`}
                >
                    <span
                        className={`text--red`}
                    >{`${totalSkusChecked} out of ${totalSkus} `}</span>
                    <span>SKUs completed </span>
                    <button
                        className="btn width--50 mtm"
                        onClick={this.handleOpenSkuList}
                    >
                        Jump to SKU
                    </button>
                </div>
                <div className={`pl`}>
                    <p className={`fs--large text--bolder mrs`}>
                        {itemType === 'Item'
                            ? currentSku.split('_')[0]
                            : currentSku}
                    </p>
                    <p className={`fs--medium`}>{itemName}</p>
                </div>
                {batches
                    .sort((a, b) => a.expires_at - b.expires_at)
                    .map((batch) => (
                        <SkuStockInput
                            key={batch.id}
                            batch={batch}
                            lastUpdated={lastUpdated}
                            isResubmit={isResubmit}
                            onChange={this.handleGetStockInputs}
                            value={stockQuantity[batch.id] || ''}
                        />
                    ))}
                {openSkuList && <div className={`stock_check__drawer-mask`}></div>}
                <div className={`stock_check__drawer ${openSkuList ? 'open' : ''}`}>
                    <div
                        className={`stock_check__nav-btn drawer-close`}
                        onClick={this.handleCloseSkuList}
                    >
                        <svg className="icon">
                            <use xlinkHref={`#icon-cross`} />
                        </svg>
                    </div>
                    <p className="fs--medium-large text--sofiapro text--left mbm">
                        Select SKU
                    </p>
                    <div className={`stock_check__drawer-buttons`}>
                        {Object.keys(skusByGroup).map((category) => (
                            <Fragment key={category}>
                                <h3 className="width--100 text--left fs--medium-large text--bolder">{`${
                                    itemType === 'Item' ? 'Type' : ''
                                } ${category}`}</h3>
                                {skusByGroup[category].sort().map((sku) => (
                                    <div
                                        key={sku}
                                        className={`stock_check__drawer-button ${
                                            isSkuChecked(sku)
                                                ? 'selected'
                                                : 'unchecked'
                                        } ${
                                            currentSku === sku ? 'current' : ''
                                        } ${
                                            itemType === 'Item'
                                                ? ''
                                                : 'font-small'
                                        }`}
                                        onClick={() =>
                                            this.handleSetCurrentSku(sku)
                                        }
                                    >
                                        {itemType === 'Item'
                                            ? sku.split('_')[0]
                                            : sku}
                                    </div>
                                ))}
                            </Fragment>
                        ))}
                    </div>
                </div>
                <div className="footer-bottom">
                    <button
                        className={`btn width--75 ${
                            isChecked && !isResubmit ? 'btn-selected' : ''
                        }`}
                        onClick={this.handleSubmit}
                        disabled={!hasInput && (!isChecked || isResubmit)}
                    >
                        {isChecked && !isResubmit
                            ? 'Do Again'
                            : 'Submit and Next'}
                    </button>
                </div>
                {showConfirmationModel && (
                    <Modal>
                        <h3>
                            Attention! Big difference in count, are you sure
                            your count is correct?
                        </h3>
                        {errorMessage.map(
                            ({ id, count, virtualStock, batchDates }) => (
                                <div className="mbm" key={id}>
                                    <h3>{batchDates}</h3>
                                    <div
                                        className={`display--flex justify--space-between flex-align-items-center`}
                                    >
                                        <div>
                                            <div className="modal-content">{`Virtual Stock = ${virtualStock} meals`}</div>
                                            <div className="modal-content">{`Your count = ${count} meals`}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                        <div className="display--flex justify--space-around">
                            <button
                                className="btn ms"
                                onClick={this.handleCloseConfirmationModal}
                            >
                                Count again
                            </button>
                            <button
                                className="btn btn-primary ms"
                                onClick={this.sendRequest}
                            >
                                Yes, save count
                            </button>
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}

export default StockCheckUpdate;
