import { observable, action } from "mobx";
import PrintStore from './PrintStore';

class SkuStore {    
    @action createSkuPrintZpl = (print, state) => {
        let printString = "";
        let sku = state.sku.replace(/_/g, '-');

        for (let i = 0; i < state.total; i++) {
            printString += `
            CT~~CD,~CC^~CT~
            ^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR4,4~SD15^JUS^LRN^CI0^XZ
            ^XA
            ^MMT
            ^PW472
            ^LL0177
            ^LS0
            ^FT15,136^A0N,125,129^FB442,1,0,C^FH\^FD${sku}^FS
            ^PQ1,0,1,Y^XZ
            `
        }

        if (print) {
            console.log(printString);
            PrintStore.writeToSelectedPrinter(printString)
        }
    }
}

const store = new SkuStore();
export default store;
