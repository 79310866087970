import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { DateInput } from "@blueprintjs/datetime";
import moment from "moment";
import OutboundSkuList from './OutboundSkuList';
import Select from "react-select";

@inject('OutboundStore', 'PrintStore')
@observer
class OutboundLabelAutomatic extends Component {
    handleClickPrint = e => {
        const { OutboundStore: { createOutboundPrintZplAuto, skus } } = this.props;
        e.preventDefault();
        createOutboundPrintZplAuto(!!skus);
    };

	render() {
        const {
            OutboundStore: {
                date,
                handleGetHubStockTransfers,
                hubStockTransfers,
                selectedSkusToPrint,
                setSelectedSkusToPrint,
                setSelectedHubs,
                selectedHubs,
                transferHubs
            },
            PrintStore: { isPrinterConnected },
            setShowPrintModal
        } = this.props;
        const selectCustomStyles = {
            control: (provided, state) => ({
                ...provided,
                width: 400,
                fontWeight: 1000,
                fontSize: 16
            }),
            menu: (provided, state) => ({
                ...provided,
                zIndex: 2,
                fontWeight: 1000,
                fontSize: 16
            }),
        }

        return (
            <Fragment>
                <div className="btn-wrapper ml">   
                    <DateInput
                        formatDate={date => moment(date).date() === moment().date()
                            ? moment(date).format('[Today •] DD/MM/YYYY')
                            : moment(date).format('DD/MM/YYYY')
                        }
                        onChange={date => handleGetHubStockTransfers(moment(date))}
                        parseDate={str => moment(str, 'DD/MM/YYYY').toDate()}
                        placeholder="Pick a date"
                        value={moment(date, 'DD/MM/YYYY').toDate()}
                        inputProps={{ leftIcon: "calendar" }}
                        className="date-input"
                        maxDate={new Date(2030,12,31)}
                    />
                    <span className="separator" />
                    <button className="btn" onClick={this.handleClickPrint} disabled={!isPrinterConnected || !hubStockTransfers}>
                        <svg fill="#fff" className="iconmoon-icon"><use xlinkHref={`#icon-print`} /></svg>
                        Print
                    </button>
                </div>
                <div className="multi-select-row">
                    {transferHubs
                        .sort()
                        .map(hubCode =>
                            <div
                                key={hubCode}
                                className={`multi-select-row__option ${selectedHubs.includes(hubCode) ? 'selected' : ''}`}
                                onClick={() => setSelectedHubs(hubCode)}
                            >
                                {hubCode}
                            </div>
                        )
                    }
                </div>
                <div className="multi-select-row">
                    {Object.keys(hubStockTransfers)
                        .sort()
                        .map(mealLetter => 
                            <div
                                key={mealLetter}
                                className={`multi-select-row__option ${selectedSkusToPrint.includes(mealLetter) ? 'selected' : ''}`}
                                onClick={() => setSelectedSkusToPrint(mealLetter)}
                            >
                                {mealLetter}
                            </div>
                        )
                    }
                </div>
                <OutboundSkuList setShowPrintModal={setShowPrintModal}/>
            </Fragment>
        )
    }
}

export default OutboundLabelAutomatic;
