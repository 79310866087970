import { action, observable, toJS } from 'mobx';
import moment from 'moment';
import * as api from '../services/api';
import PrintStore from './PrintStore';
import {getCity} from "../services/cities";

class OutboundStore {
  hubList = {
    MY_KL_CENTRAL_KITCHEN: { order: 1, code: 'PJ' },
    MY_KL_USJ: { order: 2, code: 'US' },
    MY_KL_PUDU: { order: 3, code: 'PD' },
    MY_KL_KD: { order: 4, code: 'KD' },
    MY_KL_SA: { order: 5, code: 'SA' }
  }

  @observable loading = true;
  @observable date = null;
  @observable hubStockTransfers = null;
  @observable selectedSkusToPrint = null;
  @observable skus = null;
  @observable selectedHubs = null;
  @observable transferHubs = null;
  @observable hubs = null;
  @observable skuStickerList = null;
  @observable remainingScanData = null;
  response = null;

  @action handleGetHubs = () => {
    this.loading = true;
    return api.getHubs()
        .then(res => {
          const city = getCity();
          if (res && res.length) {
            this.hubs = res.filter(hub => hub.city === city).sort();
            this.hubs.forEach(hub => {
              hub.selected = true;
            });
          }

        }).finally(() => {
          this.loading = false;
        });
  }

  @action handleGetSkus = () => {
    this.loading = true;
    api.getSkus()
    .then(res => {
      this.skus = res;
    })
    .then(() => this.handleGetHubStockTransfers())
    .catch(err => console.log(err));
  }

  parseResponseToStockTransferPlan = (res) => {
    this.hubStockTransfers = res.dates[1].stock_transfer_list.reduce((acc, cur) => {
      const { stock_transfer_plan } = cur;
      const {
        id,
        menu_item_sku: sku,
        menu_item_type: itemType,
        hub_code: hub,
        meals_per_pallet,
        quantity,
        emergency_boxes_quantity,
        emergency_boxes_quantity_used,
        quality_assurance_boxes_quantity,
        quality_assurance_boxes_quantity_used,
        meal_batch_id,
        menu_item_name
      } = stock_transfer_plan;

      if (this.selectedHubs.length && this.selectedHubs.indexOf(hub) < 0) {
        return acc;
      }

      if (this.transferHubs.indexOf(hub) < 0) {
        this.transferHubs.push(hub);
      }

      let mealLetter = null;
      if (itemType === 'Item') {
        mealLetter = sku.charAt(0);
      } else {
        mealLetter = "_Addons";
      }
      const totalQuantity = quantity +
          (emergency_boxes_quantity || 0) - (emergency_boxes_quantity_used || 0) +
          (quality_assurance_boxes_quantity || 0) - (quality_assurance_boxes_quantity_used || 0);
      let totalPallets = 0;
      if (itemType === 'Item') {
        totalPallets = meals_per_pallet ? Math.ceil(totalQuantity / meals_per_pallet) : 0;
      } else {
        totalPallets = totalQuantity;
      }
      const skuData = this.skus.find(item => item.sku === sku);

      const mealComponentParts = skuData && skuData.component_names ? skuData.component_names.split(' ') : [];
      const breakpoint = Math.ceil(mealComponentParts.length / 2);
      const mealComponentLine1 = mealComponentParts.slice(0, breakpoint).join(' ');
      const mealComponentLine2 = mealComponentParts.slice(breakpoint).join(' ');

      acc[mealLetter] = acc[mealLetter] || {};
      acc[mealLetter][sku] = acc[mealLetter][sku] || {};
      acc[mealLetter][sku][hub] = acc[mealLetter][sku][hub] || {};
      acc[mealLetter][sku][hub][id] = acc[mealLetter][sku][hub][id] || stock_transfer_plan;

      acc[mealLetter][sku][hub][id]['menuItemType'] = itemType
      acc[mealLetter][sku][hub][id]['totalPallets'] = totalPallets;
      acc[mealLetter][sku][hub][id]['totalMealsQuantity'] = totalQuantity;
      acc[mealLetter][sku][hub][id]['mealsPerPallet'] = meals_per_pallet;
      acc[mealLetter][sku][hub][id]['mealBatchId'] = meal_batch_id;
      acc[mealLetter][sku][hub][id]['menuItemName'] = menu_item_name;
      if (itemType === 'Item') {
        acc[mealLetter][sku][hub][id]['mealComponentLine1'] = mealComponentLine1;
      } else {
        acc[mealLetter][sku][hub][id]['mealComponentLine1'] = "ADDONS";
      }
      acc[mealLetter][sku][hub][id]['mealComponentLine2'] = mealComponentLine2;
      return acc;
    }, {});

    this.selectedSkusToPrint = Object.keys(this.hubStockTransfers).sort();
    if (!this.selectedHubs.length) {
      this.selectedHubs = this.transferHubs;
    }
  }

  @action handleGetHubStockTransfers = (date = moment()) => {
    this.date = date.format('DD-MM-YYYY');
    this.loading = true;
    api.getHubStockTransfers(this.date)
      .then(res => {
        this.response = res;
        this.transferHubs = [];
        this.selectedHubs = [];
        this.parseResponseToStockTransferPlan(res);
      })
      .catch(err => console.log(err))
      .then(() => this.handleGetRemainingScan())
  }

  @action handleGetRemainingScan = () => {
    const data = {
      transfer_date: this.date,
      hub_codes: this.transferHubs
    }
    this.loading = true;
    api.getRemainingScan(data)
      .then(res => {
        console.log(res);
        this.remainingScanData = {};
        if (res.list) {
          res.list.forEach(entry => {
            this.remainingScanData[entry.hub_code] = entry.unfinished_scan;
          });
        }
        this.loading = false;
      })
  }

  @action setSelectedSkusToPrint = (selectedSku) => {
    if (this.selectedSkusToPrint.includes(selectedSku)) {
      this.selectedSkusToPrint = this.selectedSkusToPrint.filter(sku => sku !== selectedSku);
    } else {
      this.selectedSkusToPrint.push(selectedSku);
      this.selectedSkusToPrint.sort();
    }
  }

  @action setSelectedHubs = (selectedHub) => {
    if (this.selectedHubs.includes(selectedHub)) {
      this.selectedHubs = this.selectedHubs.filter(hub => hub !== selectedHub);
    } else {
      this.selectedHubs.push(selectedHub);
      this.selectedHubs.sort();
    }
    this.parseResponseToStockTransferPlan(this.response);
  }

  getPalletCountPerSku = (mealLetter, sku) => {
    return Object.entries(this.hubStockTransfers[mealLetter][sku])
      .reduce((skuTotal, [_hub, stockTransferPlans]) =>
        skuTotal + Object.keys(stockTransferPlans)
          .reduce((hubTotal, id) => hubTotal + stockTransferPlans[id].totalPallets, 0)
      , 0);
  }

  getRemainingScanData = (sku) => {
    let remaining = 0;
    if (!this.remainingScanData) {
      return remaining;
    }
    this.selectedHubs.forEach(hubCode => {
      const hubRemaining = this.remainingScanData[hubCode] ? this.remainingScanData[hubCode][sku] : 0;
      remaining += hubRemaining ? hubRemaining : 0;
    });
    return remaining
  }

  getOutboundLabelPrintStringSingle = ({
    menuItemType,
    sku,
    hubCode,
    mealComponentLine1,
    mealComponentLine2,
    transferDate,
    useBy,
    mealsPerPallet,
    barcode,
    mealBatchId,
    index,
    totalPalletCount,
    currentMealsForThisPallet}) => {
    const isInvertedColor = sku.startsWith('R24');
    const palletQrCode = barcode + '-' + currentMealsForThisPallet + '/' + mealsPerPallet + '-' + index + '-' + totalPalletCount;
    const stickerIndex = ("" + index).padStart(3, '0') + "/" + ("" + totalPalletCount).padStart(3, '0');
    return `
      CT~~CD,~CC^~CT~
      ^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR4,4~SD15^JUS^LRN^CI0^XZ
      ^XA
      ^MMT
      ^PW1181
      ^LL0591
      ^LS0
      ^FO890,1^GB0,588,4^FS
      ^FO410,74^GB480,0,4^FS
      ^FO410,443^GB480,0,4^FS
      ^FO410,369^GB480,0,4^FS
      ^FO410,258^GB480,0,4^FS
      ^FO410,184^GB480,0,4^FS
      ${isInvertedColor?`^FO410,262^GB480,20,107^FS`:''}
      ^FT585,60^A0N,42,40^FB144,1,0,C^FH\^FDHUB^FS
      ^FT906,70^A0N,40,38^FB285,1,0,C^FH\^FDTRANSFER DATE^FS
      ^FT495,241^A0N,42,40^FB323,1,0,C^FH\^FDMEAL BATCH ID^FS
      ^FT495,426^A0N,42,40^FB323,1,0,C^FH\^FDMEAL NAME^FS
      ^FT963,290^A0N,42,40^FB151,1,0,C^FH\^FDUSED BY^FS
      ${
      isInvertedColor?
          `^FT405,337^A0N,58,57^FB510,1,0,C^FH\^FR\^FD${mealBatchId ? mealBatchId : sku.split('_')[0]}^FS`
          :
          `^FT405,337^A0N,58,57^FB510,1,0,C^FH\^FD${mealBatchId ? mealBatchId : sku.split('_')[0]}^FS`
  }
      ^FT430,520^A0N,48,47^FB465,1,0,C^FH\^FD${mealComponentLine1}^FS
      ^FT990,152^A0N,67,67^FH\^FD${transferDate}^FS
      ^FT872,359^A0N,48,47^FB344,1,0,C^FH\^FD${useBy}^FS
      ^FT594,150^A0N,58,57^FB129,1,0,C^FH\^FD${hubCode}^FS
       ^FT430,580^A0N,48,47^FB465,1,0,C^FH\^FD${mealComponentLine2}^FS
      ^FO892,409^GB287,0,4^FS
      ^FO892,220^GB287,0,4^FS
      ^FT933,471^A0N,42,40^FB224,1,0,C^FH\^FDMEAL COUNT^FS     
      ^FT960,550^A0N,58,57^FB168,1,0,C^FH\^FD${currentMealsForThisPallet}^FS
      ^FO410,1^GB0,588,4^FS
      ^FT10,500^BQN,2,9^FH\^FDQA,${palletQrCode}^FS
      ^FT20,570^A0N,48,47^FB344,1,0,C^FH^FD${stickerIndex}^FS
      ^PQ1,0,1,Y^XZ
      `
  }

  getOutboundLabelPrintString = ({
    menuItemType,
    sku,
    hubCode,
    mealComponentLine1,
    mealComponentLine2,
    transferDate,
    useBy,
    totalMealsQuantity,
    mealsPerPallet,
    barcode,
    mealBatchId
  }) => {

    let totalQuantity = totalMealsQuantity;
    let printStrings = [];
    let index = 1;
    const totalPalletCount = mealsPerPallet ? Math.floor(totalMealsQuantity / mealsPerPallet) + (totalMealsQuantity % mealsPerPallet > 0 ? 1 : 0) : 1;
    while (totalQuantity > 0) {

      if (menuItemType === 'Addons') {
         mealsPerPallet = totalQuantity;
      }

      let currentMealsForThisPallet = (totalQuantity > mealsPerPallet) ? mealsPerPallet : totalQuantity;
      totalQuantity = (totalQuantity > mealsPerPallet) ? totalQuantity - mealsPerPallet : 0;
      if (currentMealsForThisPallet > 0) {
        const printData = {
          menuItemType,
          sku,
          hubCode,
          mealComponentLine1,
          mealComponentLine2,
          transferDate,
          useBy,
          mealsPerPallet,
          barcode,
          mealBatchId,
          index,
          totalPalletCount,
          currentMealsForThisPallet
        }
        printStrings.push(this.getOutboundLabelPrintStringSingle(printData));
        index++;
      }
    }

    return printStrings;
  }

  @action createOutboundPrintZplManual = (print, printData) => {
    let printString = "";

    printString += this.getOutboundLabelPrintString(printData).join('');

    if (print) {
      console.log(printString);
      PrintStore.writeToSelectedPrinter(printString);
    }
  }

  generateZplForTransferPlan = (sku, hub, planId, transferPlan) => {
    const {
      menuItemType,
      totalMealsQuantity,
      mealsPerPallet,
      transfer_date,
      arrival_at,
      expires_at,
      mealBatchId,
      menuItemName
    } = transferPlan;

    const hubCode = this.hubList[hub] ? this.hubList[hub].code : hub.split('_')[2].substring(0, 2);
    const transferDateMoment = moment(transfer_date, 'x');
    const arrivalAtMoment = moment(arrival_at, 'x');
    const expiresAtMoment = moment(expires_at, 'x');
    const transferDate = transferDateMoment.format('DD/M');
    const useByFromDate = arrivalAtMoment.format('DD/M');
    const useByToDate = expiresAtMoment.subtract(1, 'days').format('DD/M');
    const useBy = `${useByFromDate} - ${useByToDate}`;
    const barcode = `${sku.replace(/_/g, '_5F')}-${transfer_date}-${planId}`;

    const nameParts = menuItemName ? menuItemName.split(' ') : [];
    const breakpoint = Math.ceil(nameParts.length / 2);
    const mealComponentLine1 = nameParts.slice(0, breakpoint).join(' ');
    const mealComponentLine2 = nameParts.slice(breakpoint).join(' ');
    const printData = {
      menuItemType,
      hubCode,
      sku,
      mealComponentLine1,
      mealComponentLine2,
      transferDate,
      useBy,
      totalMealsQuantity,
      mealsPerPallet,
      barcode,
      mealBatchId: mealBatchId ? mealBatchId.replace(/_/g, '_5F') : ''
    }

    let printStrings = [];
    if (totalMealsQuantity > 0) {
      console.log(sku + " " + mealsPerPallet + " " + totalMealsQuantity);
      printStrings = printStrings.concat(this.getOutboundLabelPrintString(printData));
    }
    return printStrings;
  }

  @action createOutboundPrintZplAuto = (print) => {
    let printString = "";
    this.selectedSkusToPrint
      .forEach(mealLetter => {
        const skuType = this.hubStockTransfers[mealLetter];

        Object.entries(skuType)
          .sort()
          .forEach(([sku, hubs]) => {
            printString += `
              CT~~CD,~CC^~CT~
              ^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR4,4~SD15^JUS^LRN^CI0^XZ
              ^XA
              ^MMT
              ^PW1181
              ^LL0591
              ^LS0
              ^FO512,32^GFA,01536,01536,00012,:Z64:eJztkzEOgzAQBC+icBPJP4CPIPEznKfxFJ5AmQKxuNrbNJEDlhBSrhpZ1s5tcWb3mqdwKzwov5zT5IyZ+MBCbvAmB6zkCCgztAMYOsh7kv/A5qoPZn4D9wb4PlnFPb+oCrQlql+1BarqDXGqoaoqNVTVmYaiqtVQVUcbmqjyf+dOribKNQVX5T2pyqHmswmPwr3957rZAVuQ0R4=:6616
              ^FO576,32^GFA,01152,01152,00012,:Z64:eJzVkzEKxDAMBCVcuAn4B+en+GnJ0/KUe0LKK0I2rrQbSBEOEu5UDUbsWAKZ/WMNwi/hJjxOZMyBjndwEs5Yggs+wRUr47ExHmA8MMn7zH4KKigooCCDggQKOofAVQARjCJoIqgiKCLIDwhOp3HcvK5Lgl9c1yXB6TRfrCvYhU36u4Bn0OQ8qpxQ4X86B1rmdVhivDnjzRY71A7lmdnk:EFDB
              ^FT241,464^A0N,208,206^FB703,1,0,C^FH\^FD${sku.replace(/_/g, '-')}^FS
              ^FO580,46^GB0,170,18^FS
              ^PQ1,0,1,Y^XZ
              `

            Object.keys(hubs)
              .sort((a, b) => this.hubList[a] ? this.hubList[a].order : this.hubList.length + 1
                - this.hubList[b] ? this.hubList[b].order : this.hubList.length + 1
              )
              .forEach(hub => {
                const transferPlans = skuType[sku][hub];

                Object.keys(transferPlans)
                  .forEach(id => {
                    const transferPlan = skuType[sku][hub][id];
                    const printStrings = this.generateZplForTransferPlan(sku, hub, id, transferPlan);
                    printString += printStrings.join('');
                  })
              })
          })
    })

    if (print) {
      console.log(printString);
      PrintStore.writeToSelectedPrinter(printString);
    }
  }

  @action handleSubmitBarcode = (data) => {
    return api.scanOutboundBarcode(data)
      .then(res => {
        if (!res.status == 200) {
          alert(res.message);
        }
      })
      .catch(err => {
        alert(err.message)
        console.log(err)
      });
  }

  @action handleCompleteScan = (data) => {
    return api.submitCompleteScan(data)
        .catch(err => {
          alert(err.message)
          console.log(err)
        });
  }

  @action handleSelectSticker = (index, value) => {
    if (!this.skuStickerList) {
      return;
    }
    this.skuStickerList[index].selected = value;
  }

  @action toggleAllStickers = () => {
    if (!this.skuStickerList) {
      return;
    }
    const initialValue = this.skuStickerList[0].selected;
    this.skuStickerList.forEach(sticker => {
      sticker.selected = !initialValue;
    })
  }

  @action handlePrintSelectedStickers = () => {
    if (!this.skuStickerList) {
      return;
    }
    let printString = "";
    this.skuStickerList.forEach(sticker => {
      if (sticker.selected) {
        printString += sticker.zpl;
      }
    })
    PrintStore.writeToSelectedPrinter(printString);
  }

  @action getSkuStickerList = (sku, mealLetter) => {
    this.skuStickerList = []
    if (!this.hubStockTransfers) {
      return;
    }

    this.selectedHubs.forEach(hubCode => {
      Object.keys(this.hubStockTransfers[mealLetter][sku][hubCode]).map(planId => {
        const transferPlan = this.hubStockTransfers[mealLetter][sku][hubCode][planId];
        const totalPallet = transferPlan['totalPallets'];
        const mealBatchId = transferPlan['mealBatchId'];
        const printStrings = this.generateZplForTransferPlan(sku, hubCode, planId, transferPlan);
        for (let i=0; i<totalPallet; i++) {
          const displayIndex = i+1
          const stickerIndex = ("" + displayIndex).padStart(3, '0') + "-" + ("" + totalPallet).padStart(3, '0');
          this.skuStickerList.push({id:`${sku}-${mealBatchId}-${hubCode}-${stickerIndex}`, selected: true, zpl: printStrings[i]});
        }
      });
    });

  }

  @action handleSelectHubToComplete = (index, value) => {
    if (!this.hubs) {
      return;
    }
    this.hubs[index].selected = value;
  }

  toggleAllHubs = () => {
    if (!this.hubs) {
      return;
    }
    const initialValue = this.hubs[0].selected;
    this.hubs.forEach(hub => {
      hub.selected = !initialValue;
    })
  }
}

const store = new OutboundStore();
export default store;
