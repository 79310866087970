import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import OutboundLabelAutomatic from './OutboundLabelAutomatic';
import OutboundLabelManual from './OutboundLabelManual';
import Loading from '../Loading';
import OutboundStickerModal from "./OutboundStickerModal";

@inject('OutboundStore')
@observer
class OutboundLabel extends Component {
    state = {
        isPrintManual: false,
        showPrintModal: false
    }

    componentDidMount = () => {
        const { OutboundStore: { handleGetSkus } } = this.props;
        handleGetSkus();
    }

    handleChangePrintMode = () => {
        this.setState(prevState => ({ 
            isPrintManual: !prevState.isPrintManual
        }));
    }

    setShowPrintModal = (show) => {
        this.setState(prevState => ({
            showPrintModal: show
        }));
    }

	render() {
        const { isPrintManual, showPrintModal  } = this.state;
        const { OutboundStore: { loading } } = this.props;

        if (loading) {
            return <Loading />
        }
        
        return (
            <Fragment>
                <h1 className="mtxl">Outbound Label</h1>
                <button className="btn" onClick={this.handleChangePrintMode}>
                    {isPrintManual ? 'Go back' : 'Go to manual print mode'}
                </button>
                {isPrintManual ? (
                    <OutboundLabelManual />
                ) : (
                    <OutboundLabelAutomatic setShowPrintModal={this.setShowPrintModal}/>
                )}

                {showPrintModal ? (
                    <OutboundStickerModal setShowPrintModal={this.setShowPrintModal}/>
                ) : null}
            </Fragment>
        )
    }
}

export default OutboundLabel;
