import { action, observable } from "mobx"

class HeaderStore {
  @observable pages = ['factory', 'outbound', 'sku', 'qrcode', 'new-factory', 'outbound-qrcode', 'stock-check', 'component-stock'];
  @observable currentPage = this.pages[0];

  @action setPage = (page) => {
    this.currentPage = page;
  }
}

const store = new HeaderStore()
export default store