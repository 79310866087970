import React, { Component, Fragment } from 'react';
import StockCheckHeader from './StockCheckHeader';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';

@observer
class StockCheck extends Component {
    render() {
        return (
            <Fragment>
                <StockCheckHeader title="Daily SKU Check" backLink="" />
                <div className="inventory-menu">
                    <h2>{moment().format('dddd - DD/MM/YYYY')}</h2>
                    <div className="btn-wrapper mm mtxl">

                        <Link to={{
                            pathname: `/stock-check/meals`,
                            state: { itemType: 'Item' }
                        }}>
                            <button className="btn btn-primary width--300px">
                                Meals
                            </button>
                        </Link>
                    </div>
                    <div className="btn-wrapper mm">
                        <Link to={{
                            pathname: `/stock-check/addons`,
                            state: { itemType: 'Addons' }
                        }}>
                            <button className="btn btn-primary width--300px">
                                Addons
                            </button>
                        </Link>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default StockCheck;