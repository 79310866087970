import React, {Component, Fragment} from 'react';
import {inject, observer} from 'mobx-react';
import QrReader from 'react-qr-reader';
import Switch from 'react-toggle-switch'
import Select from "react-select";
import moment from "moment";
import {DateInput} from "@blueprintjs/datetime";

const audio = new Audio(`${process.env.PUBLIC_URL}/qrcode_scan_sound.mp3`);

@inject('OutboundStore')
@observer
class OutboundQRCodeScanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            meal_count_in_pallet: '',
            meals_per_pallet: '',
            showCompletedConfirmationModal: false,
            completeResult: null,
            showRequestAudioPlayPermission: true,
            processing: false,
            isAutoSubmit: true,
            scanData: null,
            scanMode: '',
            selectedDate: moment()
        }
    }

    handleSetData(data) {
        this.setState({
            scanData: data
        })
    }

    handleChangeCompleteDate(date) {
        this.setState({
            selectedDate: date
        })
    }

    handleSetScanMode(scanMode) {
        this.setState({
            scanMode: scanMode
        })
    }

    handleScan = data => {
        const { processing, isAutoSubmit } = this.state;
        // Block next scan if browser is busy
        if (processing) {
            return;
        }

        if (data) {
            this.setState({
                processing: true
            });

            console.log(data)
            this.playAudio();
            const qrCode = data.split('-');
            const sku = qrCode[0];
            const transfer_date = qrCode[1];
            const transfer_plan_id = qrCode[2];
            let mealsCountInAndPerPallet = qrCode[3].split('/');
            const meal_count_in_pallet = +mealsCountInAndPerPallet[0];
            const meals_per_pallet = +mealsCountInAndPerPallet[1];
            const unique_pallet_count = +qrCode[4];
            const total_pallet = +qrCode[5];
            this.handleSetData({
                sku,
                transfer_date,
                transfer_plan_id,
                meal_count_in_pallet,
                unique_pallet_count,
                total_pallet
            });
            this.setState({
                meal_count_in_pallet: meal_count_in_pallet,
                meals_per_pallet: meals_per_pallet
            });

            if (isAutoSubmit) {
                this.handleSubmit();
            }
        }
    }

    playAudio() {
        let promise = audio.play();
        if (promise !== undefined) {
            promise.catch(error => {
                // Auto-play was prevented
                // Show a UI element to let the user manually start playback
            }).then(() => {
                // Auto-play started
            });
        }
    }

    handleError = err => {
        console.error(err)
    }

    handleChange = (e) => {
        const {value} = e.target;
        this.setState({meal_count_in_pallet: value});
    }

    toggleAutoSubmit = (e) => {
        this.setState(prevState => {
            return {
                isAutoSubmit: !prevState.isAutoSubmit
            };
        });
    }

    handleSubmit = () => {
        const {meal_count_in_pallet, isAutoSubmit, scanMode, scanData} = this.state;
        const {OutboundStore: {handleSubmitBarcode}} = this.props;

        // Remove scan data to disable submit button
        this.handleSetScanMode(undefined);
        this.handleSetData(null);
        handleSubmitBarcode({
            ...scanData,
            ...(scanMode === 'loose' && meal_count_in_pallet && {meal_count_in_pallet: +meal_count_in_pallet})
        }).then(() => {
            if (isAutoSubmit) {
                // Wait 1.5s before next scan
                setTimeout(() => {this.setState({processing: false})}, 1500);
            } else {
                this.setState({processing: false});
            }
        });
        this.setState({showCompletedConfirmationModal: false});
    }

    handleCompleteScan = (force) => {
        const {selectedDate} = this.state;
        const {OutboundStore: {handleCompleteScan, hubs}} = this.props;
        let selectedHubs = [];
        if (!hubs) {
            alert("No hub selected");
        }

        hubs.forEach(hub => {
            if (hub.selected) {
                selectedHubs.push(hub.code);
            }
        })
        const data = {
            transfer_date: selectedDate.format('DD-MM-YYYY'),
            hub_codes: selectedHubs,
            force
        }
        handleCompleteScan(data).then(res => {
            if (!force) {
                if (res.list) {
                    this.setState({completeResult: res.list})
                } else {
                    this.handleCloseCompleteConfirmationModal();
                    alert("Completed");
                }
            } else {
                this.handleCloseCompleteConfirmationModal();
                alert("Completed");
            }
        })
    }

    handleShowCompletedConfirmationModal = () => {
        const { OutboundStore: { handleGetHubs, hubs } } = this.props;
        if (!hubs) {
            handleGetHubs().then(() => {
                this.setState({showCompletedConfirmationModal: true});
            });
        } else {
            this.setState({showCompletedConfirmationModal: true});
        }
    }

    handleCloseCompleteConfirmationModal = () => {
        this.setState({
            showCompletedConfirmationModal: false,
            processing: false,
            completeResult: null
        });
        this.handleSetScanMode(undefined);
        this.handleSetData(null);
    }

    handleSelectHub = (e, index) => {
        const { OutboundStore: { handleSelectHubToComplete } } = this.props;
        handleSelectHubToComplete(index, e.target.checked);
    }

    render() {
        const {
            meal_count_in_pallet,
            meals_per_pallet,
            showCompletedConfirmationModal,
            showRequestAudioPlayPermission,
            processing,
            isAutoSubmit,
            scanData,
            scanMode,
            selectedDate,
            completeResult
        } = this.state;
        const {
            OutboundStore: {hubs, toggleAllHubs}
        } = this.props;
        return (
            <Fragment>
                <div className={"qr-reader " + `${processing ? 'success' : ''}`}>
                    <QrReader
                        delay={500}
                        onError={this.handleError}
                        onScan={this.handleScan}
                        facingMode="environment"
                        style={{width: '100%'}}
                    />
                </div>
                <div className="ms">
                    {!scanMode && (
                        <Fragment>
                            <div>
                                <Switch className={"mrm "} onClick={this.toggleAutoSubmit} on={isAutoSubmit}/>
                                <label className="form-label">Auto Submit</label>
                            </div>
                            <hr/>
                            <button
                                className={"btn mrm " + `${meals_per_pallet === meal_count_in_pallet ? 'btn-selected' : ''}`}
                                disabled={!scanData || isAutoSubmit}
                                onClick={() => this.handleSubmit()}>
                                {scanData && meals_per_pallet !== meal_count_in_pallet ? 'Pallet number ' + scanData.unique_pallet_count : ''}
                                {scanData && meals_per_pallet !== meal_count_in_pallet ? <br/> : ''}
                                Submit & Next
                            </button>
                            <button
                                className={"btn mrm " + `${meals_per_pallet !== meal_count_in_pallet ? 'btn-selected' : ''}`}
                                disabled={!scanData || isAutoSubmit}
                                onClick={() => this.handleSetScanMode('loose')}>
                                Loose
                            </button>
                            <button className="btn btn-selected"
                                    disabled={processing}
                                    onClick={() => this.handleShowCompletedConfirmationModal()}>
                                Finish
                            </button>
                        </Fragment>
                    )}
                    {scanMode === 'loose' && (
                        <Fragment>
                            <h2>How many loose meals?</h2>
                            <input
                                className="border--rounded border--solid fs--medium-medium-large text--center text--black text--bolder width--100px ps mm"
                                type="number"
                                pattern="[0-9]*"
                                value={meal_count_in_pallet}
                                onChange={this.handleChange}
                            />
                            <Fragment>
                                <h3>Pallet number {scanData ? scanData.unique_pallet_count : ''}</h3>
                                <button className="btn btn-selected width--100 mtm mbm"
                                        onClick={() => this.handleSubmit()}>
                                    Submit {meal_count_in_pallet} meals & Next
                                </button>
                            </Fragment>

                        </Fragment>
                    )}
                    {showCompletedConfirmationModal &&
                    <Fragment>
                        <div className="print-modal">
                            <div className="print-modal__content">
                                <div>
                                    <span className="print-modal__close"
                                          onClick={this.handleCloseCompleteConfirmationModal}>&times;</span>
                                </div>
                                {!completeResult && (
                                    <Fragment>
                                        <div>
                                            <button className={`btn mlxs`} onClick={toggleAllHubs}>
                                                Select / Deselect All
                                            </button>
                                        </div>
                                        <div>
                                            <table className={"full-size"}>
                                                <tbody>
                                                {hubs.map((hub, index) => {
                                                        return (
                                                            <tr key={hub.code}>
                                                                <td style={{fontSize: "10px"}}>{hub.code}</td>
                                                                <td>
                                                                    <div>
                                                                        <input
                                                                            className={`border--rounded border--solid`}
                                                                            type="checkbox"
                                                                            checked={hub.selected}
                                                                            onChange={(e) => {this.handleSelectHub(e, index)}}
                                                                            style={{width: "20px", height: "20px"}}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )})
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className={"mtm"}>
                                            <DateInput
                                                formatDate={date => moment(date).format('DD/MM/YYYY')}
                                                onChange={date => this.handleChangeCompleteDate(moment(date))}
                                                placeholder="Pick a date"
                                                value={selectedDate.toDate()}
                                                className="date-input full-size-field"
                                                maxDate={new Date(2030,12,31)}
                                            />
                                        </div>
                                    </Fragment>
                                )}
                                {completeResult && (
                                    <Fragment>
                                        <h3>Missing scans</h3>
                                        <table style={{width: '100%', maxHeight: '300px'}}>
                                            <tbody>
                                            {completeResult
                                                .map((entry, index) => {
                                                    return (
                                                        <Fragment key={entry.hub_code}>
                                                            <tr>
                                                                <td colSpan={2} className={'inverted-cell'}>{entry.hub_code}</td>
                                                            </tr>
                                                            {Object.keys(entry.unfinished_scan).sort()
                                                                .map(sku => (
                                                                    <Fragment key={entry.hub_code + '-' + sku}>
                                                                        <tr>
                                                                            <td>
                                                                                {sku}
                                                                            </td>
                                                                            <td>
                                                                                {entry.unfinished_scan[sku]}
                                                                            </td>
                                                                        </tr>
                                                                    </Fragment>
                                                            ))}
                                                        </Fragment>
                                                    )})
                                            }
                                            </tbody>
                                        </table>
                                        <label className={"mtm"}>This will update transfer plan. Are you sure?</label>
                                    </Fragment>
                                )}
                                <div className={"mtm"}>
                                    <button className="btn mrm"
                                            onClick={this.handleCloseCompleteConfirmationModal}>Cancel
                                    </button>
                                    <button className="btn btn-primary"
                                            onClick={() => this.handleCompleteScan(!!completeResult)}>Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Fragment>}
                    {showRequestAudioPlayPermission &&
                    <Fragment>
                        <div className="print-modal">
                            <div className="print-modal__content">
                                <div>
                                    <span className="print-modal__close" onClick={this.closeModal}>&times;</span>
                                    <h1>Allow this website to play sound?</h1>
                                </div>
                                <div>
                                    <button className="btn btn-primary"
                                            onClick={() => {
                                                this.playAudio();
                                                this.setState({showRequestAudioPlayPermission: false});
                                            }}>Allow
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Fragment>}
                </div>
            </Fragment>
        );
    }
}

export default OutboundQRCodeScanner;
