import { action, computed, observable, toJS } from 'mobx';
import * as api from '../services/api';
import _ from 'lodash';
//import UserStore from './common/UserStore';
import moment from 'moment';

class StockCheckStore {
    stockCheckStartTime = '00:01';
    stockCheckEndTime = '23:59';
    last_updated = null;
    @observable loading = false;
    @observable date = moment();
    @observable productionBatches = {};
    @observable currentSku = '';
    @observable itemType = 'Item';
    @observable skuSalesUnavailableReasons = [];
    @observable availabilityOfSkus = {};

    @computed get skuList() {
        return Object.keys(this.productionBatches);
    }

    @computed get totalSkus() {
        return this.skuList.length;
    }

    @computed get totalSkusChecked() {
        return this.skuList.reduce(
            (total, sku) => (this.isSkuChecked(sku) ? total + 1 : total),
            0
        );
    }

    @computed get skusByLetter() {
        return this.skuList.reduce((acc, sku) => {
            const letter = sku.charAt(0);
            acc[letter] = acc[letter] ? acc[letter].concat([sku]) : [sku];
            return acc;
        }, {});
    }

    @computed get skusByCategory() {
        return this.skuList.reduce((acc, sku) => {
            const category =
                this.productionBatches[sku]['batches'][0]['menu_item_category'];
            acc[category] = acc[category] ? acc[category].concat([sku]) : [sku];
            return acc;
        }, {});
    }

    @computed get skus() {
        return this.skuList
            .reduce((acc, sku) => {
                acc.push({
                    sku: sku,
                    quantity_left: this.productionBatches[sku][
                        'batches'
                        ].reduce((a, batch) => a + batch.quantity_left, 0),
                    virtual_stock: this.productionBatches[sku][
                        'batches'
                        ].reduce((a, batch) => a + batch.virtual_stock, 0),
                    preorders:
                        this.productionBatches[sku]['batches'].reduce(
                            (a, batch) => a + batch.virtual_stock,
                            0
                        ) -
                        this.productionBatches[sku]['batches'].reduce(
                            (a, batch) => a + batch.quantity_left,
                            0
                        ),
                });
                return acc;
            }, [])
            .sort((a, b) => a.quantity_left - b.quantity_left);
    }

    isSkuChecked = (sku) => {
        const batches = this.productionBatches[sku]['batches'];
        return batches.some((batch) => batch.isStockCheckCompleted);
    };

    @computed get initialSku() {
        for (const sku in this.productionBatches) {
            const batches = this.productionBatches[sku]['batches'];
            if (batches.some((batch) => !batch.isStockCheckCompleted)) return sku;
        }
        return Object.keys(this.productionBatches)[0];
    }

    @action lastUpdated = (stock_check_tracker) => {
        const start = moment(this.stockCheckStartTime, 'HH:mm');
        const end = moment(this.stockCheckEndTime, 'HH:mm');

        const stockCheckTransferLog =
            stock_check_tracker &&
            stock_check_tracker.filter((entry) => {
                const { production, updated_at } = entry;
                const updatedAtHub = !production;
                const updatedInTimeframe = moment(updated_at, 'x').isBetween(
                    start,
                    end
                );

                return updatedAtHub && updatedInTimeframe;
            });

        return (
            stockCheckTransferLog &&
            stockCheckTransferLog[stockCheckTransferLog.length - 1]
        );
    };

    @action handleSetCurrentSku = (sku) => {
        this.currentSku = sku;
        if (sku) this.handleGetVirtualStockForSkuBatches();
    };

    @action handleSetItemType = (type) => {
        this.itemType = type;
    };

    @action isCheckCompleted = (stock_check_tracker) => {
        const start = moment(this.stockCheckStartTime, 'HH:mm');
        const end = moment(this.stockCheckEndTime, 'HH:mm');

        return (
            stock_check_tracker &&
            stock_check_tracker.some((entry) => {
                const { production, updated_at } = entry;
                const updatedAtHub = !production;
                const updatedInTimeframe = moment(updated_at, 'x').isBetween(
                    start,
                    end
                );

                return updatedAtHub && updatedInTimeframe;
            })
        );
    };

    @action handleUpdateSaleAvailablity = (body, itemType) => {
        api.updateSKUSaleAvailability(body)
            .then((res) => {
                this.handleGetSKUSalesAvailability(itemType);
            })
            .catch((err) => {});
    };
    @action handleGetSKUSalesAvailability = (itemType) => {
        const date = this.date.format('DD-MM-YYYY');

        api.getSKUSalesAvailability(date, itemType)
            .then((res) => {
                let availabilityOfSkus = {};
                res.items.forEach((item) => {
                    availabilityOfSkus[item.sku] = {
                        available_for_sale_quantity:
                        item.available_for_sale_quantity,
                        menu_item_id: item.menu_item_id,
                    };
                });
                // availabilityOfSkus will be an object where key=sku and value of that key will be a further object with available_for_sale_quantity and menu_item_id field
                this.availabilityOfSkus = availabilityOfSkus;
                this.skuSalesUnavailableReasons = res.unavailable_reasons;
            })
            .catch((err) => {});
    };

    @action handleGetProductionBatches = (setInitialSku = true) => {
        this.loading = true;

        const date = this.date.format('DD-MM-YYYY');
        api.getProductionBatchesForStockCheck(date, this.itemType)
            .then((res) => {
                let productionBatches = res.batches
                    .sort((a, b) =>
                        a.color === b.color ? 0 : a.color > b.color ? 1 : -1
                    )
                    .filter((b) => {
                        if (
                            b.quantity_left !== 0 ||
                            !b.stock_check_tracker ||
                            b.stock_check_tracker.length === 0
                        ) {
                            return true;
                        }
                        let lastStockCheckEntry =
                            b.stock_check_tracker[
                            b.stock_check_tracker.length - 1
                                ];
                        // If last stock check entry quantity and delta are both 0, skip the batch by returning false
                        return !(
                            lastStockCheckEntry.quantity === 0 &&
                            lastStockCheckEntry.delta === 0
                        );
                    });
                this.productionBatches = productionBatches.reduce(
                    (acc, batch) => {
                        const { color: sku } = batch;
                        this.setBatchStatus(batch);

                        acc[sku] = acc[sku] || {};
                        acc[sku]['batches'] = acc[sku]['batches']
                            ? acc[sku]['batches'].concat([batch])
                            : [batch];

                        return acc;
                    },
                    {}
                );

                if (setInitialSku) {
                    this.handleSetCurrentSku(this.initialSku);
                } else {
                    this.handleSetCurrentSku(this.currentSku);
                }
                this.loading = false;
                this.last_updated = moment();
            })
            .catch((err) => {
                //UserStore.message = err.message;
            });
    };

    setBatchStatus(batch) {
        batch.isStockCheckCompleted = this.isCheckCompleted(
            batch.stock_check_tracker
        );
    }

    @action handleSetActualStock = (
        batch,
        setInitialSku = true
    ) => {
        this.loading = true;
        const apiCall = api.setActualStock;
        return apiCall(batch)
            .then((updated_batch) => {
                if (
                    moment
                        .duration(moment().diff(this.last_updated))
                        .seconds() > 60
                ) {
                    // do full update to make sure we're up-to-date
                    this.handleGetProductionBatches(setInitialSku);
                } else {
                    this.setBatchStatus(updated_batch);
                    // only update the changes batch
                    const index = _.findIndex(
                        this.productionBatches[this.currentSku]['batches'],
                        { id: updated_batch.id }
                    );
                    this.productionBatches[this.currentSku]['batches'].splice(
                        index,
                        1,
                        updated_batch
                    );

                    if (setInitialSku) {
                        this.handleSetCurrentSku(this.initialSku);
                    } else {
                        this.handleSetCurrentSku(this.currentSku);
                    }
                }
                this.loading = false;
            })
            .catch((err) => {
                //UserStore.message = err.message;
                this.loading = false;
            });
    };

    @action getVirtualStockForAllSkusAndBatches = () => {
        this.skuList.forEach((sku) => {
            this.handleGetVirtualStockForSkuBatches(sku);
        });
    };

    @action handleGetVirtualStockForSkuBatches = (sku = this.currentSku) => {
        const batches = this.productionBatches[sku]['batches'];
        batches.forEach((batch) => {
            this.handleGetVirtualStock(batch);
        });
    };

    @action handleGetVirtualStock = (batch) => {
        api.getVirtualStock(batch.menu_item_id, batch.id)
            .then((res) => {
                batch.virtual_stock = res.virtual_stock;
            })
            .catch((err) => {
                //UserStore.message = err.message;
            });
    };
}

const store = new StockCheckStore();
export default store;
