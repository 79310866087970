import { action } from "mobx";
import * as api from "../services/api";

class ComponentStockStore {
    @action sendComponentStockUpsertRequest = (data) => {
        api.upsertComponentStock(data)
            .then(res => {
                alert(res.message);
            })
            .catch(err => alert(err));
    }
}

const store = new ComponentStockStore();
export default store;
