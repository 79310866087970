import parameters from "../configs/parameters";
import {getCity} from "./cities";
import Cookies from "js-cookie";

const url = parameters['services'];
const basicAuth = parameters['basicAuth'];

/**
 * Gets headers for HTTP requests against our backend
 * @param token Bearer token if required (nullable)
 * @param omitContentType If true we omit the content-type header
 * @returns {*}
 */
const getHeaders = (omitContentType) => {
    const token = Cookies.get('token');
    let headers = {
        "Accept-Language": "en",
        'Brand': "dahmakan",
        // fallback to basic auth in case of missing token
        'Authorization': token ? `Bearer ${token}` : `Basic ${basicAuth}`,
        'City': getCity(),
    };

    if (localStorage.getItem('hub_code')) {
        headers['Hub-Code'] = localStorage.getItem('hub_code')
    }
    // in case we want javascript to decide which content-type to use
    if (!omitContentType) {
        return Object.assign({"Content-Type": "application/json"}, headers)
    }
    return headers;
}

export const login = data =>
    fetch(`${url}/v2/oauth/token`, {
        method: "POST",
        headers: Object.assign(getHeaders(true)),
        body: data
    })
        .then(handleErrors)
        .then(res => res.json());

export const getHubs = () =>
    fetch(`${url}/v5/rider/hubs`, {
        method: "GET",
        headers: getHeaders()
    })
        .then(handleErrors)
        .then(res => res.json());

export const getHubStockTransfers = (date) =>
    fetch(`${url}/v1/admin/transfer-plans?date=${date}`, {
        method: "GET",
        headers: getHeaders()
    })
        .then(handleErrors)
        .then(res => res.json());

export const getSkus = () =>
    fetch(`${url}/v1/menus/sku`, {
        method: "GET",
        headers: getHeaders()
    })
        .then(handleErrors)
        .then(res => res.json());

export const getUnitSkus = () =>
    fetch(`${url}/v1/admin/inventory/units/skus`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const getComponentDetails = (unitSku) =>
    fetch(`${url}/v1/odoo/product-detail?unitSku=${unitSku}`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const getProductionBatches = (date) =>
    fetch(`${url}/v1/barcode/inventory-batch?date=${date}&item_type=Item`, {
        method: "GET",
        headers: getHeaders()
    })
        .then(handleErrors)
        .then(res => res.json());

export const scanBarcode = data => {
    let success;

    return fetch(`${url}/v1/barcode/inventory-batch/produced`, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(data)
    })
    .then(handleErrors)
    .then(res => res.json());
}

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const upsertBatch = data => {
    return fetch(`${url}/v1/menus/batch`, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(data)
    }).then(res => res);
}

export const getBatchById = (sku, id) =>
    fetch(`${url}/v1/barcode/inventory-batch-by-id?sku=${sku}&batchId=${id}`, {
        method: "GET",
        headers: getHeaders()
    });

export const scanOutboundBarcode = data => {
    return fetch(`${url}/v1/barcode/stock-transfer/outbound`, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(data)
    })
        .then(handleErrors)
        .then(res => res);
}

export const submitCompleteScan = data => {
    return fetch(`${url}/v1/barcode/stock-transfer/outbound/complete`, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(data)
    })
        .then(handleErrors)
        .then(res => res.json());
}

export const getRemainingScan = data => {
    return fetch(`${url}/v1/barcode/stock-transfer/outbound/unfinished`, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(data)
    })
        .then(handleErrors)
        .then(res => res.json());
}

export const updateSKUSaleAvailability = (body) =>
    fetch(`${url}/v1/menus/menu-item/available-for-sale/`, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const getSKUSalesAvailability = (date, itemType) =>
    fetch(
        `${url}/v1/menus/available-for-sale?date=${date}&item_type=${itemType}`,
        {
            method: 'GET',
            headers: getHeaders(),
        }
    )
        .then(handleErrors)
        .then((res) => res.json());

export const setActualStock = (item) => {
    let success;

    return fetch(`${url}/v1/menus/batch/stock-check/${item.menuId}`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(item),
    })
        .then((response) => {
            if (response.ok) {
                success = true;
            }
            return response.json();
        })
        .then((data) => {
            if (!success) {
                throw Error(data.message);
            }
            return data;
        });
};

export const getVirtualStock = (menuId, id) =>
    fetch(`${url}/v4/production/batches/virtual-stock/${menuId}/${id}`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const getProductionBatchesForStockCheck = (date, item_type) =>
    fetch(
        `${url}/v4/production/batches-on-date?date=${date}&item_type=${item_type}`,
        {
            method: 'GET',
            headers: getHeaders(),
        }
    )
        .then(handleErrors)
        .then((res) => res.json());

export const upsertComponentStock = data => {
    return fetch(`${url}/import/units-csv`, {
        method: "POST",
        headers: Object.assign(getHeaders(true)),
        body: data
    })
        .then(handleErrors)
        .then(res => res.json());
}