export default {
    // Base URL
    baseUrl: 'https://attendance.dahmakan.com/',
    
    // Hostname for static endpoints
    staticAssets: 'https://attendance.dahmakan.com/',
    
    // Hostname for API endpoints
    services: 'https://api.dahmakan.com',
}
