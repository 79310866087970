// App Config for staging profile
export default {
    // Base URL
    baseUrl: 'http://dahmakan-admin-attendance-staging.s3-website-ap-southeast-1.amazonaws.com/',

    // Hostname for static endpoints
    staticAssets: 'http://dahmakan-admin-attendance-staging.s3-website-ap-southeast-1.amazonaws.com/',

    // Hostname for API endpoints
    services: 'https://staging-api.dahmakan.com'
};
