import Cookies from "js-cookie";
import parameters from "../configs/parameters";

/**
 * Keys of this object are used for use with our API, values are used for displaying
 * @type {{Kuala_Lumpur: string, Bangkok: string}}
 */
export const cities = {
  "Kuala_Lumpur": "Kuala Lumpur",
  "Bangkok": "Bangkok"
}

/**
 * Gets the currently selected city key
 * @returns {string}
 */
export const getCity = () => {
  return Cookies.get("city")
    ? Cookies.get("city")
    : "Kuala_Lumpur"
};

/**
 * Sets the city for future use
 * @param city
 * @returns {*}
 */
export const setCity = city => Cookies.set("city", city, {'expires': 30})

export const getCurrency = () => {
  return parameters.currencySymbolByCity[getCity()]
}