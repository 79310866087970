import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';

const base = 'print-modal'

@inject('NewFactoryStore')
@observer
class PrintStickerModal extends Component {
    handleClickPrint = (e) => {
        e.preventDefault();
        const { NewFactoryStore: { handlePrintSelectedStickers } } = this.props;
        handlePrintSelectedStickers();
        alert("Print request sent");
    }

    closeModal = (e) => {
        e.preventDefault();
        const { setPrintModalState } = this.props;
        setPrintModalState(false);
    }

    handleSelectSticker = (e, index) => {
        const { NewFactoryStore: { handleSelectSticker } } = this.props;
        handleSelectSticker(index, e.target.checked);
    }

    render() {
        const {
            NewFactoryStore: {
                stickerList,
                toggleAllStickers
            }
        } = this.props;

        return (
            <Fragment>
                <div className={`${base}`}>
                    <div className={`${base}__content`}>
                        <div>
                            <span className={`${base}__close`} onClick={this.closeModal}>&times;</span>
                            <h1>Print Selection</h1>
                        </div>
                        <div>
                            <button className={`${base}__main-button btn mlxs`} onClick={toggleAllStickers}>
                                Select / Deselect All
                            </button>
                            <button className={`${base}__main-button btn mlxs`} onClick={this.handleClickPrint}>
                                Print
                            </button>
                        </div>
                        <Fragment>
                            <table>
                                <tbody>
                                    {stickerList
                                        .map((sticker, index) => {
                                            return (
                                            <tr key={sticker.id}>
                                                <td>{sticker.id}</td>
                                                <td>
                                                    <div className={`${base}__value`}>
                                                        <input
                                                            className={`${base}__value border--rounded border--solid`}
                                                            type="checkbox"
                                                            checked={sticker.selected}
                                                            onChange={(e) => {this.handleSelectSticker(e, index)}}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )})
                                    }
                                </tbody>
                            </table>
                        </Fragment>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default PrintStickerModal;