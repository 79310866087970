import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import Select from 'react-select';
import { DateInput } from '@blueprintjs/datetime';
import moment from 'moment';

@inject('FactoryStore', 'PrintStore')
@observer
class FactoryLabelManual extends Component {
    state = {
        total: '',
    }

    componentDidMount() {
        const { FactoryStore } = this.props;
        FactoryStore.handleGetUnitSkus();
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value.toUpperCase() });
    }

    handleClickPrint = e => {
        const { FactoryStore } = this.props;
        const fields = { ...this.state };
        e.preventDefault();
        FactoryStore.createFactoryPrintZplManual(true, fields);
    };

    handleSetSelectedUnitSku = (unitSku) => {
        const { FactoryStore } = this.props;
        FactoryStore.setSelectedUnitSku(unitSku);
    }

    handleSetDate = (momentDate) => {
        const {
            FactoryStore: { handleSetDate },
        } = this.props;
        const date = moment(momentDate).format('DD-MM-YYYY');
        handleSetDate(date);
    };

    handleExpiryDate = (exipryDate) => {
        const {
            FactoryStore: { handleExpiryDate },
        } = this.props;
        handleExpiryDate(exipryDate);
    };

    render() {
        const {
            total,
        } = this.state;

        const { PrintStore: { isPrinterConnected }, FactoryStore: {unitSkus, selectedUnitSku, selectedUnitSkuName, productionDate, expirationTime, uomQuantity} } = this.props;

        let expiryDate = null;
        if (productionDate && expirationTime) {
            const productionDateParts = productionDate.split('-');
            const productionDay = parseInt(productionDateParts[0]);
            const productionMonth = parseInt(productionDateParts[1]) - 1;
            const productionYear = parseInt(productionDateParts[2]);
            let date = new Date(productionYear, productionMonth, productionDay);
            date.setDate(date.getDate() + expirationTime);
            const [year, month, day] = date.toISOString().split('T')[0].split('-');
            expiryDate = day + "-" + month + "-" + year;
            this.handleExpiryDate(expiryDate);
        }

        let unitSkuOptions;
        if (unitSkus){
            unitSkuOptions = unitSkus.filter((unitSku) => !unitSku.subComponent)
        }

        const disabled = !isPrinterConnected || !selectedUnitSku || !selectedUnitSkuName || !productionDate || !expiryDate || !total;

        return (
            <Fragment>
                <form className="form-grid">
                    <label className="form-label">Component Code</label>
                    <Select
                            className='form-input border--rounded border--solid'
                            value={selectedUnitSku}
                            autoFocus
                            required
                            options={unitSkuOptions}
                            style={{ width: '100%', marginBottom: '10px' }}
                            name='unitSku'
                            onChange={this.handleSetSelectedUnitSku}
                            placeholder='Search for component code'
                        />

                    <label className="form-label">Component Name</label>
                    <input
                        className="form-input border--rounded border--solid"
                        disabled
                        name="mealComponentName"
                        type="text"
                        value={selectedUnitSkuName}
                    />

                    <label className="form-label">UOM Quantity</label>
                    <input
                        className="form-input border--rounded border--solid"
                        disabled
                        name="uomQuantity"
                        type="text"
                        value={uomQuantity}
                    />
                    <label className="form-label">Production Date</label>
                    <DateInput
                        formatDate={(date) =>
                            moment(date).date() === moment().date()
                                ? moment(date).format(
                                        '[Today •] DD/MM/YYYY'
                                    )
                                : moment(date).format('DD/MM/YYYY')
                        }
                        onChange={(date) => this.handleSetDate(date)}
                        parseDate={(str) =>
                            moment(str, 'DD/MM/YYYY').toDate()
                        }
                        placeholder="Pick a date"
                        value={moment(productionDate, 'DD/MM/YYYY').toDate()}
                        className='date-input single left-align'
                    />
                    <label className="form-label">Expiry Date</label>
                    <input
                        className="form-input border--rounded border--solid"
                        disabled
                        name="expiryDate"
                        type="text"
                        value={expiryDate}
                    />
                    <label className="form-label">Number of labels to print</label>
                    <input
                        className="form-input border--rounded border--solid"
                        required
                        name="total"
                        type="number"
                        value={total}
                        onChange={this.handleChange}
                        placeholder="45"
                    />
                    <button className="btn form-button" onClick={this.handleClickPrint} disabled={disabled}>
                        <svg fill="#fff" className="iconmoon-icon"><use xlinkHref={`#icon-print`} /></svg>
                        Print
                    </button>
                </form>
            </Fragment>
        )
    }
}

export default FactoryLabelManual;
