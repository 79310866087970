import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';

@inject('OutboundStore', 'PrintStore')
@observer
class OutboundLabelManual extends Component {
    state = {
        hubCode: '',
        sku: '',
        mealComponentLine1: '',
        mealComponentLine2: '',
        transferDate: '',
        useBy: '',
        mealsPerPallet: '',
        totalMealsQuantity: ''
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value.toUpperCase()});
    }

    handleClickPrint = e => {
        const { OutboundStore } = this.props;
        const fields = {...this.state};
        e.preventDefault();
        OutboundStore.createOutboundPrintZplManual(true, fields);
    };

	render() {
        const {
            hubCode,
            sku,
            mealComponentLine1,
            mealComponentLine2,
            transferDate,
            useBy,
            mealsPerPallet,
            totalMealsQuantity
        } = this.state;
        const { PrintStore: { isPrinterConnected } } = this.props;

        const disabled = !isPrinterConnected || !hubCode || !sku || !mealComponentLine1 || !transferDate || !useBy || !mealsPerPallet || !totalMealsQuantity;
        
        return (
            <Fragment>
                <form className="form-grid">
                    <label className="form-label">Hub</label>
                    <input
                        className="form-input border--rounded border--solid"
                        required
                        name="hubCode"
                        type="text"
                        maxLength="2"
                        value={hubCode}
                        onChange={this.handleChange}
                        placeholder="PJ"
                    />
                    <label className="form-label">Meal ID</label>
                    <input
                        className="form-input border--rounded border--solid"
                        required
                        name="sku"
                        type="text"
                        value={sku}
                        onChange={this.handleChange}
                        placeholder="B07_11"
                    />
                    <label className="form-label">Meal Component Line 1</label>
                    <input
                        className="form-input border--rounded border--solid"
                        required
                        name="mealComponentLine1"
                        type="text"
                        maxLength="15"
                        value={mealComponentLine1}
                        onChange={this.handleChange}
                        placeholder="HEALTHY MINCED"
                    />
                    <label className="form-label">Meal Component Line 2</label>
                    <input
                        className="form-input border--rounded border--solid"
                        required
                        name="mealComponentLine2"
                        type="text"
                        maxLength="15"
                        value={mealComponentLine2}
                        onChange={this.handleChange}
                        placeholder="CHICKEN LASAGNA"
                    />
                    <label className="form-label">Transfer Date</label>
                    <input
                        className="form-input border--rounded border--solid"
                        required
                        name="transferDate"
                        type="text"
                        value={transferDate}
                        onChange={this.handleChange}
                        placeholder="29/1"
                    />
                    <label className="form-label">Used By</label>
                    <input
                        className="form-input border--rounded border--solid"
                        required
                        name="useBy"
                        type="text"
                        value={useBy}
                        onChange={this.handleChange}
                        placeholder="30/1 - 31/1"
                    />
                    <label className="form-label">Meals Per Pallet</label>
                    <input
                        className="form-input border--rounded border--solid"
                        required
                        name="mealsPerPallet"
                        type="number"
                        value={mealsPerPallet}
                        onChange={this.handleChange}
                        placeholder="3"
                    />
                    <label className="form-label">Total Meals Count</label>
                    <input
                        className="form-input border--rounded border--solid"
                        required
                        name="totalMealsQuantity"
                        type="number"
                        value={totalMealsQuantity}
                        onChange={this.handleChange}
                        placeholder="8"
                    />
                    <button className="btn form-button" onClick={this.handleClickPrint} disabled={disabled}>
                        <svg fill="#fff" className="iconmoon-icon"><use xlinkHref={`#icon-print`} /></svg>
                        Print
                    </button>
                </form>
            </Fragment>
        )
    }
}

export default OutboundLabelManual;
