// App Config for development profile
export default {
    // Base URL
    baseUrl: 'http://localhost:3000',

    // Hostname for static endpoints
    staticAssets: 'http://localhost:3000',

    // Hostname for API endpoints
    // services: 'http://52.74.100.84',
    services: 'https://staging-api.dahmakan.com',
};
