import {observable, action} from "mobx";
import Cookies from "js-cookie";
import * as api from "../services/api";
import { clearHubDataFromStorage } from "../services/hubs";

class UserStore {
    @observable authedUser = null;
    @observable loading = false;
    
    @action login = (data) => {
        this.loading = true;
        api.login(data)
            .then(res => {
                Cookies.set('token', res.access_token, { 'expires': 30 })
                this.authedUser = res.access_token
            })
            .catch(e => {
                alert(e)
            })
            .finally(() => this.loading = false)
    };

    @action logout = () => {
        Cookies.remove('token');
        clearHubDataFromStorage();
        window.location.replace("/");
    };
}

const store = new UserStore()
export default store