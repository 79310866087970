import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import moment from "moment";

const base = 'transfer-list'

@inject('NewFactoryStore', 'PrintStore')
@observer
class FactorySkuList extends Component {
    render() {
			const {
				NewFactoryStore: {
					selectedSkusToPrint,
					getSortedBatches,
					getMealCountForBatch,
					getPalletCountForBatch,
					getBatchAvailableTime,
					getMealBatchId
				},
				PrintStore: {
					isPrinterConnected
				}
			} = this.props;

			return (
				<div className={`${base} factory`}>
                    {selectedSkusToPrint
                        .sort()
						.map(sku => (
							<Fragment key={sku}>
								<div className={`${base}__header__factory`}>{`${sku}`}</div>
								{Object.keys(getSortedBatches(sku))
									.map(batchId => (
									<Fragment key={batchId}>
										<div className={`${base}__meal-sku`}>{getMealBatchId(sku, batchId)}</div>
										<div className={`${base}__meal-sku`}>{getBatchAvailableTime(sku, batchId)}</div>
										<div className={`${base}__meal-sku`}>{getMealCountForBatch(sku, batchId)}</div>
										<div className={`${base}__meal-count`}>{getPalletCountForBatch(sku, batchId)}</div>
										<button className="btn" onClick={(e) => this.handleClickPrint(sku, batchId, e)} disabled={!isPrinterConnected}>Print</button>
										<button className="btn" onClick={(e) => this.handleBatchEdit(sku, batchId, e)}>Edit</button>
									</Fragment>
								))}
							</Fragment>
						))
					}
				</div>
			);
  	}

	handleClickPrint = (sku, batchId, e) => {
    	e.preventDefault();
		const { NewFactoryStore: { handlePrintPalletStickers }, setPrintModalState } = this.props;
		handlePrintPalletStickers(sku, batchId);
		setPrintModalState(true);
	};

	handleBatchEdit = (sku, batchId, e) => {
		e.preventDefault();
		const { NewFactoryStore: { getInventoryBatch }, openEditBatchWindow } = this.props;
		openEditBatchWindow(getInventoryBatch(sku, batchId));
	};
}

export default FactorySkuList;
