import { observable, action, toJS } from "mobx";
import moment from 'moment';
import * as api from '../services/api';
import PrintStore from './PrintStore';

class FactoryStore {
    @observable loading = false;
    @observable productionDate = moment();
    @observable productionBatches = null;
    @observable selectedSkusToPrint = null;
    @observable skus = null;
    @observable stickerList = [];

    @action handleGetSkus = () => {
        this.loading = true;
        api.getSkus()
        .then(res => {
            this.skus = res;
        })
        .then(() => this.handleGetProductionBatches())
        .catch(err => console.log(err));
    }

    @action handleGetSkuList = () => {
        this.loading = true;
        api.getSkus()
            .then(res => {
                this.skus = res;
                this.loading = false;
            });
    }

    clearBatchesData = () => {
        this.productionBatches = null;
    }

    getPalletCountPerSku = (mealLetter, sku) => {
        return this.productionBatches[mealLetter][sku].totalPallets;
    }

    getPalletCountForBatch = (sku, id) => {
        return this.productionBatches[sku][id].totalPallets;
    }

    getBatchAvailableTime = (sku, id) => {
        const batch = this.productionBatches[sku][id];
        return moment(batch.arrivalAt).format('DD/MM/YYYY') + ' - ' + moment(batch.expiresAt).format('DD/MM/YYYY');
    }

    getMealCountForBatch = (sku, id) => {
        return this.productionBatches[sku][id].mealCount;
    }

    getMealBatchId = (sku, id) => {
        return this.productionBatches[sku][id].mealBatchId;
    }

    getSortedBatches = (sku) => {
        return this.productionBatches[sku];
    }

    getInventoryBatch = (sku, id) => {
        return this.productionBatches[sku][id];
    }

    @action handleSetProductionDate = (productionDate) => {
        this.productionDate = productionDate;
    }

    @action handleGetProductionBatches = () => {
        const date = moment(this.productionDate).format('DD-MM-YYYY');
        api.getProductionBatches(date)
            .then(res => {
                this.productionBatches = res.batches.reduce((acc, cur) => {
                    const {
                        color: sku,
                        quantity_left,
                        id,
                        arrival_at,
                        expires_at,
                        menu_item_name,
                        meal_batch_id,
                        component_batch_ids
                    } = cur;

                    if (quantity_left) {
                        const skuData = this.skus.find(item => item.sku === sku);
                        const { meals_per_pallet } = skuData
                        const totalPallets = meals_per_pallet ? Math.ceil(quantity_left / meals_per_pallet) : 0;

                        acc[sku] = acc[sku] || {};
                        acc[sku][id] = acc[sku][id] || {};

                        acc[sku][id]['sku'] = sku;
                        acc[sku][id]['totalPallets'] = totalPallets;
                        acc[sku][id]['mealsPerPallet'] = meals_per_pallet;
                        acc[sku][id]['mealCount'] = quantity_left;
                        acc[sku][id]['arrivalAt'] = arrival_at;
                        acc[sku][id]['expiresAt'] = expires_at;
                        acc[sku][id]['menu_item_name'] = menu_item_name;
                        acc[sku][id]['mealBatchId'] = meal_batch_id;
                        acc[sku][id]['componentBatchIds'] = component_batch_ids;
                        acc[sku][id]['id'] = id;
                    }

                    return acc;
                }, {});

                this.selectedSkusToPrint = [];
                res.batches.forEach(batch => {
                    const { color: sku, quantity_left } = batch;

                    if (quantity_left && this.selectedSkusToPrint.indexOf(sku) < 0) {
                        this.selectedSkusToPrint.push(sku);
                    }
                })
                this.loading = false;
            })
            .catch(err => console.log(err));
    }

    @action setSelectedSkusToPrint = (selectedSku) => {
        if (this.selectedSkusToPrint.includes(selectedSku)) {
          this.selectedSkusToPrint = this.selectedSkusToPrint.filter(sku => sku !== selectedSku);
        } else {
          this.selectedSkusToPrint.push(selectedSku);
          this.selectedSkusToPrint.sort();
        }
    }

    getFactoryLabelPrintString = ({
        sku,
        mealName,
        componentBatchIds,
        mealBatchId,
        productionDate,
        expiryDate,
        mealsPerPallet,
        mealsInPallet,
        totalPallets,
        batchId
    }, palletNo) => {
        const productionDateBarcode = moment(productionDate, 'DD/MM/YYYY').format('DDMMYYYY');
        const expiryDateBarcode = moment(expiryDate, 'DD/MM/YYYY').format('DDMMYYYY');
        const qrCode = `${sku}-${productionDateBarcode}-${expiryDateBarcode}-${mealsInPallet}/${mealsPerPallet}-${palletNo}-${totalPallets}-${batchId}`;

        return `
            CT~~CD,~CC^~CT~
            ^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR4,4~SD15^JUS^LRN^CI0^XZ
            ^XA
            ^MMT
            ^PW1181
            ^LL0591
            ^LS0
            ^FT13,86^A0N,75,74^FH\^FD${mealBatchId ? mealBatchId : sku}^FS
            ^FT13,186^A0N,75,74^FH\^FDMeals Per Pallet: ${mealsInPallet}/${mealsPerPallet}^FS
            ^FT13,375^A0N,29,28^FH\^FDName: ${mealName}^FS
            ^FT13,258^A0N,42,40^FH\^FDProduction Date: ${productionDate}^FS
            ^FT13,315^A0N,42,40^FH\^FDExpiry Date: ${expiryDate}^FS
            ^FO2,425^GB768,0,4^FS
            ^FO2,200^GB768,0,4^FS
            ^FO769,2^GB0,586,4^FS
            ^FT13,410^A0N,29,28^FH\^FDComp: ${componentBatchIds}^FS
            ^FT13,470^A0N,25,22^FH^FD${qrCode}^FS
            ^FO3,329^GB768,0,4^FS
            ^FT875,555^A0N,33,33^FH\^FD${palletNo}/${totalPallets}^FS
            ^FT835,423^BQN,2,8
            ^FH\^FDQA,${qrCode}^FS
            ^PQ1,0,1,Y^XZ
        `
    }

    @action createFactoryPrintZplManual = (print, printData) => {
        let printString = "";
        printData.batchId = 'MANUAL_BATCH_ID';
        for (let i = 0; i < printData.totalPallets; i++) {
            const count = i + 1;
            const palletNo = ("" + count).padStart(3, '0');
            printString += this.getFactoryLabelPrintString(printData, palletNo);
        }

        if (print) {
            PrintStore.writeToSelectedPrinter(printString);
        }
    }

    @action createFactoryPrintZplAuto = (print) => {
        let printString = "";

        this.selectedSkusToPrint
            .sort()
            .forEach(mealLetter => {
                const skus= this.selectedSkusToPrint[mealLetter];
                skus
                    .sort()
                    .forEach(sku => {
                        const skuData = this.productionBatches[mealLetter][sku]
                        const {
                            mealComponentParts,
                            menu_item_name,
                            mealsPerPallet,
                            totalPallets,
                            expires_at,
                            mealCount,
                            batchId
                        } = skuData;


                        const productionDate = this.productionDate.format('DD/MM/YYYY');

                        const expiryDateMoment = moment(expires_at, 'x').subtract(1, "days");
                        const expiryDate = expiryDateMoment.format('DD/MM/YYYY');

                        let printData = {
                            sku: sku.replace(/_/g, '_5F'),
                            mealName: menu_item_name,
                            mealComponentParts,
                            productionDate,
                            expiryDate,
                            mealsPerPallet: ("" + mealsPerPallet).padStart(2, '0'),
                            totalPallets: ("" + totalPallets).padStart(3, '0'),
                            batchId: batchId
                        }

                        printString += `
                        CT~~CD,~CC^~CT~
                        ^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR4,4~SD15^JUS^LRN^CI0^XZ
                        ^XA
                        ^MMT
                        ^PW1181
                        ^LL0591
                        ^LS0
                        ^FO512,32^GFA,01536,01536,00012,:Z64:eJztkzEOgzAQBC+icBPJP4CPIPEznKfxFJ5AmQKxuNrbNJEDlhBSrhpZ1s5tcWb3mqdwKzwov5zT5IyZ+MBCbvAmB6zkCCgztAMYOsh7kv/A5qoPZn4D9wb4PlnFPb+oCrQlql+1BarqDXGqoaoqNVTVmYaiqtVQVUcbmqjyf+dOribKNQVX5T2pyqHmswmPwr3957rZAVuQ0R4=:6616
                        ^FO576,32^GFA,01152,01152,00012,:Z64:eJzVkzEKxDAMBCVcuAn4B+en+GnJ0/KUe0LKK0I2rrQbSBEOEu5UDUbsWAKZ/WMNwi/hJjxOZMyBjndwEs5Yggs+wRUr47ExHmA8MMn7zH4KKigooCCDggQKOofAVQARjCJoIqgiKCLIDwhOp3HcvK5Lgl9c1yXB6TRfrCvYhU36u4Bn0OQ8qpxQ4X86B1rmdVhivDnjzRY71A7lmdnk:EFDB
                        ^FT241,464^A0N,208,206^FB703,1,0,C^FH\^FD${sku.replace(/_/g, '_5F')}^FS
                        ^FO580,46^GB0,170,18^FS
                        ^PQ1,0,1,Y^XZ
                        `

                        for (let i = 0; i < totalPallets; i++) {
                            const count = i + 1;
                            const palletNo = ("" + count).padStart(3, '0');
                            const mealsInPallet = mealCount && count == totalPallets ? mealCount % mealsPerPallet : mealsPerPallet;
                            printData.mealsPerPallet = ("" + mealsInPallet).padStart(2, '0');
                            printString += this.getFactoryLabelPrintString(printData, palletNo);
                        }
                    })
            })

        if (print) {
            PrintStore.writeToSelectedPrinter(printString);
        }
    }

    getStickerSkuPart = (sku) => {
        return `
        CT~~CD,~CC^~CT~
        ^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR4,4~SD15^JUS^LRN^CI0^XZ
        ^XA
        ^MMT
        ^PW1181
        ^LL0591
        ^LS0
        ^FO512,32^GFA,01536,01536,00012,:Z64:eJztkzEOgzAQBC+icBPJP4CPIPEznKfxFJ5AmQKxuNrbNJEDlhBSrhpZ1s5tcWb3mqdwKzwov5zT5IyZ+MBCbvAmB6zkCCgztAMYOsh7kv/A5qoPZn4D9wb4PlnFPb+oCrQlql+1BarqDXGqoaoqNVTVmYaiqtVQVUcbmqjyf+dOribKNQVX5T2pyqHmswmPwr3957rZAVuQ0R4=:6616
        ^FO576,32^GFA,01152,01152,00012,:Z64:eJzVkzEKxDAMBCVcuAn4B+en+GnJ0/KUe0LKK0I2rrQbSBEOEu5UDUbsWAKZ/WMNwi/hJjxOZMyBjndwEs5Yggs+wRUr47ExHmA8MMn7zH4KKigooCCDggQKOofAVQARjCJoIqgiKCLIDwhOp3HcvK5Lgl9c1yXB6TRfrCvYhU36u4Bn0OQ8qpxQ4X86B1rmdVhivDnjzRY71A7lmdnk:EFDB
        ^FT241,464^A0N,208,206^FB703,1,0,C^FH\^FD${sku.replace(/_/g, '_5F')}^FS
        ^FO580,46^GB0,170,18^FS
        ^PQ1,0,1,Y^XZ
        `
    }

    getDisplayStickerId = (printData, palletNo) => {
        const {
            sku,
            productionDate,
            expiryDate,
            mealsInPallet,
            totalPallets
        } = printData;
        const productionDateBarcode = moment(productionDate, 'DD/MM/YYYY').format('DDMMYYYY');
        const expiryDateBarcode = moment(expiryDate, 'DD/MM/YYYY').format('DDMMYYYY');
        return `${sku.replace(/_5F/g, '_')}-${productionDateBarcode}-${expiryDateBarcode}-${mealsInPallet}-${palletNo}-${totalPallets}`;
    }

    handleSelectSticker = (index, value) => {
        if (!this.stickerList) {
            return;
        }
        this.stickerList[index].selected = value;
    }

    toggleAllStickers = () => {
        if (!this.stickerList) {
            return;
        }
        const initialValue = this.stickerList[0].selected;
        this.stickerList.forEach(sticker => {
            sticker.selected = !initialValue;
        })
    }

    @action handlePrintSelectedStickers = () => {
        if (!this.stickerList) {
            return;
        }
        let printString = "";
        this.stickerList.forEach(sticker => {
            if (sticker.selected) {
                printString += sticker.zpl;
            }
        })
        PrintStore.writeToSelectedPrinter(printString);
    }

    getComponentBatchIdsDisplayString = (componentBatchIds) => {
        let displayString = componentBatchIds;
        if (componentBatchIds.length > 45) {
            displayString = componentBatchIds.substring(0, 45);
        }
        return displayString.replace(/_/g, '_5F');
    }

    @action handlePrintPalletStickers = (sku, batchId) => {
        this.stickerList = [];
        const batch = this.productionBatches[sku][batchId];
        const {
            componentBatchIds,
            mealBatchId,
            menu_item_name,
            mealsPerPallet,
            totalPallets,
            expiresAt,
            arrivalAt,
            mealCount
        } = batch;

        const productionDate = moment(arrivalAt).format('DD/MM/YYYY');
        const expiryDate = moment(expiresAt).format('DD/MM/YYYY');
        let printData = {
            sku: sku.replace(/_/g, '_5F'),
            mealName: menu_item_name,
            mealBatchId: mealBatchId ? mealBatchId.replace(/_/g, '_5F') : '',
            componentBatchIds: componentBatchIds ? this.getComponentBatchIdsDisplayString(componentBatchIds) : '',
            productionDate,
            expiryDate,
            mealsPerPallet: ("" + mealsPerPallet).padStart(2, '0'),
            totalPallets: ("" + totalPallets).padStart(3, '0'),
            batchId: batchId
        }

        for (let i = 0; i < totalPallets; i++) {
            const count = i + 1;
            const palletNo = ("" + count).padStart(3, '0');
            const mealsInPallet = mealCount && count == totalPallets && mealCount % mealsPerPallet > 0? mealCount % mealsPerPallet : mealsPerPallet;
            printData.mealsInPallet = ("" + mealsInPallet).padStart(2, '0');
            this.stickerList.push({id: this.getDisplayStickerId(printData, palletNo), selected: true, zpl: this.getFactoryLabelPrintString(printData, palletNo)});
        }
    }

    @action sendBatchUpsertRequest = (data, callback) => {
        let batch = {
            id: data.id,
            sku: data.sku,
            frozen_batch: true,
            arrival_at: data.arrivalDate,
            expires_at: data.expiryDate,
            quantity: data.quantity,
            meal_batch_id: data.mealBatchId,
            status: data.status,
        }

        if (data.componentBatchIds) {
            batch.components_batch_ids = [];
            const parts = data.componentBatchIds.split(',');
            parts.forEach((value) => {
                if (value.trim()) {
                    batch.components_batch_ids.push(value.trim());
                }

            })
        }

        api.upsertBatch(batch)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    alert(res.message);
                } else if (callback) {
                    callback();
                }
            })
            .catch(err => {
                // JSON parse error to handle empty response
                if (callback) {
                    callback();
                }
            });
    }
}

const store = new FactoryStore();
export default store;
