import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

const base = 'sku-entry';

@inject('StockCheckStore')
@observer
class SkuStockInput extends Component {
    handleChange = (e) => {
        const { onChange } = this.props;
        const { name, value } = e.target;
        onChange(name, value);
    };

    render() {
        const {
            batch: {
                arrival_at,
                expires_at,
                id,
                stock_check_tracker,
                isCheckCompleted,
                meal_batch_id
            },
            value,
            isResubmit,
            lastUpdated,
        } = this.props;

        const startDate = moment(arrival_at, 'x').format('DD/MM/YYYY');
        const endDate = moment(expires_at, 'x')
            .subtract(1, 'days')
            .format('DD/MM/YYYY');
        const lastUpdatedLog = lastUpdated(stock_check_tracker);

        const count = isResubmit
            ? value
            : isCheckCompleted
                ? lastUpdatedLog.quantity
                : value;

        return (
            <Fragment>
                <div className={`fs--medium-large ps bg--black text--white`}>
                    {`${meal_batch_id}: ${startDate} - ${endDate}`}
                </div>
                <div className={`${base} pl`}>
                    <label className="fs--medium-medium-large mrl">
                        Actual Stock:
                    </label>
                    <input
                        className="border--rounded border--solid fs--medium-medium-large text--center text--black text--bolder width--100px ps"
                        name={id}
                        type="number"
                        value={count}
                        onChange={this.handleChange}
                        disabled={isCheckCompleted && !isResubmit}
                    />
                    {lastUpdatedLog && (
                        <div className="text--left mtxl">
                            <h3>Last updated</h3>
                            <p>User: {lastUpdatedLog.initiator_username}</p>
                            <p>
                                Time:{' '}
                                {moment(
                                    lastUpdatedLog.updated_at,
                                    'x'
                                ).calendar()}
                            </p>
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
}

export default SkuStockInput;
